import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  createClient,
  Entry,
  CreateClientParams,
  ContentfulClientApi,
  EntryCollection
} from 'contentful';
import { from, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentfulService {
  private _deliveryClient: ContentfulClientApi;
  private _previewClient: ContentfulClientApi;

  // const isPreview = this.route.snapshot.queryParamMap.get('preview') !== null;

  get forcePreview(): boolean {
    return this.activatedRoute.snapshot.queryParamMap.has('preview');
  }

  constructor(private activatedRoute: ActivatedRoute) { }

  getEntry(
    id: string,
    query?: object,
    preview?: boolean
  ): Promise<Entry<any>> {
    return this.getClient(preview).getEntry(id, query);
  }

  getEntries<T>(query?: any, preview?: boolean): Observable<EntryCollection<T>> {
    return from(this.getClient(preview).getEntries<T>(query));
  }

  private getFirstEntry(
    query?: object,
    preview?: boolean
  ): Promise<Entry<any>> {
    return this.getClient(preview)
      .getEntries(Object.assign({}, query))
      .then(res => {
        if (res.items.length) {
          return res.items.shift();
        } else {
          throw new Error();
        }
      });
  }

  private getClient(preview?: boolean): ContentfulClientApi {
    return preview || this.forcePreview ? this.previewClient : this.deliveryClient;
  }

  private get deliveryClient(): ContentfulClientApi {
    return !this._deliveryClient
      ? (this._deliveryClient = createClient({
        space: environment.contentful.space,
        accessToken: environment.contentful.accessToken
      }))
      : this._deliveryClient;
  }

  private get previewClient(): ContentfulClientApi {
    return !this._previewClient
      ? (this._previewClient = createClient({
        space: environment.contentful.space,
        accessToken: environment.contentful.previewAccessToken,
        host: 'preview.contentful.com'
      }))
      : this._previewClient;
  }
}
