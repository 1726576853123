import { ErrorHandler, Injectable } from '@angular/core';
import { ApiService } from './services/api.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private apiService: ApiService) {}

  handleError(error) {
    // Logg non HTTP errors
    if (error instanceof HttpErrorResponse === false) {
      this.apiService.log(JSON.stringify(error.stack || error), window.location.href, 'error').subscribe(() => {});
      console.error(error);
    }
  }
}
