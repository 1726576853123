<app-header [isMinimized]="true"></app-header>

<section class="startpage">
  <div class="startpage__wrapper">
    <div class="startpage__slider" #slider>
      <app-hero [slides]="slides" [isLoading]="isLoading"></app-hero>
    </div>
    <app-categories class="startpage__categories" [assets]="categoryAssets"></app-categories>
  </div>

  <app-logo class="startpage__logo" size="medium" [isDark]="true"></app-logo>

  <app-products class="startpage__products"></app-products>

  <div class="container" *ngIf="articles?.length">
    <div class="startpage__articles">
      <div class="startpage__articles__item" *ngFor="let article of articles; trackBy: trackBySysId">
        <div class="startpage__articles__item__bg" *ngIf="article.fields?.thumbnail">
          <img [attr.src]="article.fields.thumbnail.fields.file.url" />
        </div>
        <div class="startpage__articles__item__content">
          <h3 class="startpage__articles__item__title">{{ article.fields.title }}</h3>
          <p class="startpage__articles__item__text">{{ article.fields.text }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="startpage__footer container" *ngIf="footerAsset">
    <div class="startpage__footer__bg" [style.backgroundImage]="'url(' + footerAsset.fields.file.url + ')'"></div>
  </div>
</section>

<app-footer></app-footer>
