<app-menu #menu></app-menu>

<div class="header" [ngClass]="{
    'header--minimized': isMinimized,
    'header--dark': !isMinimized && isDark,
    'header--noanimation': noAnimation,
    'header--static': isStatic
  }">
  <header #element class="header__wrapper">
      <button type="button" class="header__btn hamburger hamburger--slider" (click)="openMenu()">
        <span class="hamburger-box"> <span class="hamburger-inner"></span> </span>
      </button>

      <app-logo class="header__logo" [isDark]="!isMinimized && isDark" [visible]="isMinimized || hasLogo" size="small"></app-logo>
      
      <button type="button" class="header__cart" (click)="openCart()" *ngIf="showCart">
        <i class="header__cart__notification" *ngIf="nrOfItems">{{nrOfItems}}</i>
        <i class="icomoon icomoon-cart"></i>
      </button>
  </header>
</div>

<app-cart #cart></app-cart>