import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zip'
})
export class ZipPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value ? value.toString().replace(/[^0-9]/g, '').replace(/(\d{3})/, '$1 ') : value;
  }

}
