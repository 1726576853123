import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeroService {
  private dark: boolean = false;
  private darkSubject = new Subject<boolean>();

  private mode: string;
  private modeSubject = new Subject<string>();

  constructor() { }

  public setDark = (dark: boolean) => this.dark !== dark ? this.darkSubject.next(this.dark = dark) : null;
  public getDark = (): boolean => this.dark;
  public observeDark = (): Observable<boolean> => this.darkSubject.asObservable();

  public setMode = (mode: string) => this.mode !== mode ? this.modeSubject.next(this.mode = mode) : null;
  public getMode = (): string => this.mode;
  public observeMode = (): Observable<string> => this.modeSubject.asObservable();

}
