import { Component, OnInit } from '@angular/core';
import { SessionCacheService } from 'src/app/services/cache/session-cache.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-test-page',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestPageComponent implements OnInit {
  form: FormGroup;
  submitted: boolean;
  validCodes = ['payex'];

  get isTest(): boolean {
    return this.sessionCache.get('test');
  }

  constructor(private sessionCache: SessionCacheService, private router: Router, private fb: FormBuilder) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      password: ['', [Validators.required]]
    });
  }

  onSubmit() {
    this.submitted = true;

    const field = this.form.get('password');
    if (this.validCodes.includes(field.value)) {
      this.sessionCache.set('test', field.value);
    } else {
      field.setErrors({ incorrect: true });
      field.markAsTouched();
    }
  }

  onClickContinue() {
    this.router.navigate(['/']);
  }

  onClickLogout() {
    this.sessionCache.delete('test');
  }

  close = () => this.router.navigateByUrl('/');
}
