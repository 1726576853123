<div class="hitta" [ngClass]="{'hitta--loading': isLoading}">
  <div class="modal-header">
    <button type="button" class="btn-close btn-close-sm hamburger hamburger--slider is-active"
      (click)="modal.dismiss()">
      <span class="hamburger-box"> <span class="hamburger-inner"></span> </span>
    </button>
  </div>
  <div class="modal-body">
    <h4 class="modal-title">Välj mottagare</h4>
    <div class="hitta__loader loader loader--no-delay"></div>

    <form [formGroup]="form">
      <div #scrollcontent class="list-group list-group-flush hitta__list">
        <div class="custom-control custom-control-md custom-radio list-group-item list-group-item-action"
          *ngFor="let item of items; let i = index">
          <input type="radio" id="addressRadio{{i}}" class="custom-control-input" formControlName="address"
            [value]="item" />
          <label class="custom-control-label" for="addressRadio{{i}}">
            {{item.firstname}} {{item.lastname}}
            <br>{{item.address}}, <span class="nowrap">{{item.zip | zip}} {{item.city}}</span>
          </label>
        </div>
      </div>
    </form>

    <div class="alert alert-danger" role="alert" *ngIf="!isLoading && !items.length && !error">
      Kunde inte hitta någon mottagare.
    </div>

    <div class="alert alert-danger" role="alert" *ngIf="error">
      Ajdå! Vi kunde inte anropa servern för adresser. Vänligen försök igen om en liten stund. Felkod: {{error}}
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="cancel()">Avbryt</button>
    <button type="button" class="btn btn-primary hitta__button" (click)="confirm()"
      [disabled]="isLoading || !form.valid">
      {{ isLoading ? 'Laddar...' : 'Hämta' }}
    </button>
  </div>
</div>
