<section class="swish">
  <div class="swish__container container">
    <button type="button" class="swish__close btn-close hamburger hamburger--slider is-active" (click)="close()">
      <span class="hamburger-box"> <span class="hamburger-inner"></span> </span>
    </button>

    <div class="swish__loading" *ngIf="isLoading">
      <div class="loader loader--no-delay"></div>
    </div>

    <div class="swish__header py-5 text-center" [hidden]="isLoading">
      <app-logo [isDark]="true" class="swish__logo"></app-logo>
      <h2 class="swish__title">Slutför din beställning</h2>
    </div>

    <div class="row">
      <div class="col-md-8" *ngIf="!isLoading && !error">
        <h4 class="d-flex justify-content-between align-items-center mb-3">
          Betalning
        </h4>
        <div class="swish__qrcode">
          <div class="swish__qrcode__image">
            <img [src]="qr.image" alt="Swish" *ngIf="qr?.image" />
          </div>
          <ol class="swish__qrcode__list ">
            <li><a [href]="swishSafeUrl">Öppna Swish-appen</a> på din telefon</li>
            <li>Tryck på kamera <i class="icomoon icomoon-camera"></i> ikonen i övre hörnet</li>
            <li>Rikta din telefon mot denna skärm för att betala</li>
            <li>Godkänn din betalning med BankID </li>
          </ol>
        </div>
      </div>

      <div class="col-md-4 mb-4" [hidden]="isLoading || error">
        <h4 class="d-flex justify-content-between align-items-center mb-3 swish__cart__title">
          Varukorg
        </h4>
        <div #scrollcontent class="swish__cart">
          <p class="swish__cart__empty" *ngIf="!products || !products.length">Din varukorg är tom</p>
          <app-cart-list [products]="products" [readonly]="true"></app-cart-list>
        </div>
        <div class="swish__cart__footer" *ngIf="products && products.length">
          <table class="swish__cart__price">
            <tr>
              <td>{{getShippingTitle()}}</td>
              <td>{{getShipping() | price}}&nbsp;kr</td>
            </tr>
            <tr class="swish__cart__price__total">
              <td>Totalt inkl moms </td>
              <td>{{getTotal(true) | price}}&nbsp;kr</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="col-md-8" *ngIf="error">
        <div class="alert alert-secondary" role="alert">
          <h4 class="alert-heading">Ajdå!</h4>
          <p>Vi kunde tyvärr inte hämta din påbörjade beställning. Vänligen försök igen med en ny beställning.</p>
          <hr>
          <p class="mb-0">Felkod: {{error}}</p>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>
