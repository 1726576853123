export default class OrderItemMeta {
  public id?: number;
  public order_item_id?: string;

  public infrasec_id: string;
  public name: string;
  public value: string;

  public created_at?: string;
  public updated_at?: string;

  public static createFromAttributes(attributes: any[]): OrderItemMeta[] {
    return attributes
      ? attributes.map(attribute => ({
          infrasec_id: attribute.id,
          name: attribute.name,
          value: attribute.value
        }))
      : [];
  }
}
