<section class="text">
  <app-header isMinimized="true" class="text__header"></app-header>
  <div class="text__wrapper">
    <div class="text__slider" *ngIf="hero">
      <div class="text__slider__item">
        <div class="text__slider__item__container container">
          <h1 class="text__slider__item__title">{{ hero.fields.title }}</h1>
          <p class="text__slider__item__text">
              {{ hero.fields.text }}
          </p>
        </div>
      </div>
    </div>

    <div class="text__content" *ngIf="body || body2">
      <div class="text__content__container container">
        <div class="row">
          <div class="text__content__sidebar col" *ngIf="showSidebar">
            <h2 class="text__content__sidebar__title" *ngIf="hero.fields.slogan">{{ hero.fields.slogan }}</h2>
            <img class="text__content__sidebar__image" *ngFor="let image of heroImages" [src]="image.url" [alt]="image.title"  />
          </div>

          <div class="text__content__main col">
            <h1 *ngIf="!hero">{{ data.fields.title }}</h1>
            <article [innerHTML]="body"></article>
          </div>
        </div>

        <div class="row" *ngIf="body2">
          <div class="text__content__secondary col">
            <article [innerHTML]="body2"></article>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer class="text__footer"></app-footer>
</section>
