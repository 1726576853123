import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  HostListener,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from "@angular/core";
import { FormGroup, FormBuilder, FormArray } from "@angular/forms";
import { FilterService } from "src/app/services/filter.service";
import FilterCategory from "src/app/models/filter-category.model";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("element", { static: true }) element: ElementRef;

  form: FormGroup;
  isLoading: boolean;
  isError: any;
  selectedFiltersTitle: string;
  defaultFilter = ["säsongsbukett"];

  categories: Array<FilterCategory> = [];

  private ngUnsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private filterService: FilterService,
    private cd: ChangeDetectorRef
  ) {
    this.isLoading = true;
    this.initForm(this.categories);
  }

  ngOnInit() {
    this.filterService.categoryChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(data => {
      this.categories = data;
      this.isLoading = false;
      this.isError = !this.categories.length;
      this.initForm(this.categories);
      this.updateTopMargin();
      this.cd.markForCheck();
    });
  }

  ngAfterViewInit() {
    this.updateTopMargin();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
    this.updateTopMargin(0);
  }

  @HostListener('window:resize')
  @HostListener('window:orientationchange')
  onResize() {
    this.updateTopMargin();
  }

  initForm(categories: any[] = []) {
    // Init form with preselected values from URL query
    this.form = this.fb.group({
      categories: this.fb.array(categories.map(o => this.fb.control(o.checked)))
    });

    this.updateFilterTitle();
  }

  updateFilterTitle() {
    this.selectedFiltersTitle = this.filterService.title();
  }

  onChange() {
    const categories = this.mapToValues(
      this.form.value.categories,
      this.categories
    );
    this.filterService.setChecked(categories);
  }

  mapToValues(checked: any[], values: any[]) {
    return checked
      .map((active, index) => (active ? values[index] : null))
      .filter(v => v !== null);
  }

  get categoryControls() {
    return (<FormArray>this.form.get("categories")).controls;
  }

  private updateTopMargin(marginTop?: number) {
    const headerElement: HTMLElement = document.querySelector('.filter');
    const bodyElement: HTMLElement = document.querySelector('body');

    if (marginTop !== undefined) {
      bodyElement.style.marginTop = `${marginTop || ''}`;
      return;
    }

    if (!headerElement || !bodyElement) {
      return;
    }

    const rect = headerElement.getBoundingClientRect();
    bodyElement.style.marginTop = `${rect.height}px`;
  }
}
