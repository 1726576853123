<nav class="categories container" [class.filter--error]="isError">
  <ul class="categories__ul">
    <li class="categories__item" *ngFor="let category of categories">
      <a [routerLink]="['blommor', category.slug]" class="categories__item__link">
        <span class="categories__item__link__bg" [style.backgroundImage]="'url(' + category.image + ')'"></span>
        <span class="categories__item__link__label">{{ category.label }}</span>
      </a>
    </li>
  </ul>
</nav>
