import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { StoreService } from 'src/app/services/store.service';
import { NavService } from 'src/app/services/nav.service';
import { ApiService } from 'src/app/services/api.service';

import { finalize, switchMap, catchError, takeWhile, flatMap, takeUntil, timeout, map, mapTo } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import Cart from 'src/app/models/cart.model';
import Order from 'src/app/models/order.model';
import Product from 'src/app/models/product.model';
import { of, Observable, interval, zip, forkJoin } from 'rxjs';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-thank-you-page',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThankYouPageComponent implements OnInit, OnDestroy {
  @ViewChild('scrollcontent', { static: true }) scrollcontent: ElementRef;

  order: Order;
  cart: Cart = new Cart();

  error: any;
  hash: string;
  isLoading: boolean;
  isLoadingPayment: boolean;

  scrollbars: any;

  get isFuneral(): boolean {
    return this.order.type === 'funeral';
  }

  constructor(
    private cartService: CartService,
    private storeService: StoreService,
    private apiService: ApiService,
    private activeRoute: ActivatedRoute,
    private navService: NavService,
    private cd: ChangeDetectorRef,
    private seoService: SeoService
  ) { }

  ngOnInit() {
    this.cartService.clear();
    this.storeService.setCartState('closed');
    this.hash = this.activeRoute.snapshot.paramMap.get('hash');
    this.loadOrder();
  }

  ngOnDestroy() {
    if (this.scrollbars) {
      this.scrollbars.destroy();
    }
  }

  close = () => this.navService.goto('start');

  getTotal = (): number => this.cart.total();

  getTotalVat = () => this.cart.vat();

  getShipping = () => (this.cart.shipping ? this.cart.shipping.charge.total : 0);

  getShippingTitle = () => (this.cart.shipping ? this.cart.shipping.name : 'Frakt');

  private initCart() {
    this.scrollbars = OverlayScrollbars(this.scrollcontent.nativeElement, {
      className: 'os-theme-dark',
      sizeAutoCapable: true,
      paddingAbsolute: true,
      scrollbars: {
        autoHide: 'leave',
        autoHideDelay: 100
      }
    });
    this.cart.products = this.order.items.map(item => Product.createFromOrderItem(item));
    this.cart.shipping = this.order.shipping;
  }

  private waitPayment(): Observable<boolean> {
    this.isLoadingPayment = true;
    this.isLoading = true;
    this.cd.markForCheck();

    return forkJoin(
      interval(3000).pipe(
        switchMap(() => this.apiService.waitPayment(this.hash)),
        takeWhile(payed => !payed, true),
        finalize(() => (this.isLoadingPayment = false))
      )
    ).pipe(
      timeout(1000 * 60),
      map(response => response.includes(true))
    );
  }

  private loadOrder() {
    this.isLoading = true;

    this.seoService.setMeta('Inväntar betalning');
    this.apiService
      .getOrder(this.hash)
      .pipe(
        switchMap(order =>
          !order.payed_updated_at ? this.waitPayment().pipe(switchMap(() => this.apiService.getOrder(this.hash))) : of(order)
        ),
        finalize(() => {
          this.isLoading = false;
          this.cd.markForCheck();
        })
      )
      .subscribe(
        order => {
          this.order = order;
          this.initCart();

          // Commit order
          if (!this.order.commited_at) {
            this.apiService.commitOrder(this.order.hash).subscribe();
          }
          this.seoService.setMeta('Tack för din beställning');
        },
        response => (this.error = `${response.status} ${response.statusText}, ${response.message}`)
      );
  }
}
