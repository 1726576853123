import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { StartPageComponent } from './pages/startpage/startpage.component';
import { ProductPageComponent } from './pages/product/product.component';
import { ProductsPageComponent } from './pages/products/products.component';
import { CheckoutPageComponent } from './pages/checkout/checkout.component';
import { SwishPageComponent } from './pages/swish/swish.component';
import { ThankYouPageComponent } from './pages/thank-you/thank-you.component';
import { ContentfulResolverComponent } from './components/contentful-resolver/contentful-resolver.component';
import { TestPageComponent } from './pages/test/test.component';

const routes: Routes = [
  { path: '', component: StartPageComponent, data: { name: 'start' } },
  {
    path: 'blommor',
    data: { name: 'products' },
    children: [
      {
        path: '',
        component: ProductsPageComponent
      },
      {
        path: ':filter',
        component: ProductsPageComponent
      }
    ]
  },
  {
    path: 'produkt/:id',
    component: ProductPageComponent,
    data: { name: 'product' }
  },
  {
    path: 'kassan',
    component: CheckoutPageComponent,
    data: { name: 'checkout' }
  },
  {
    path: 'kassan/swish/:hash',
    component: SwishPageComponent,
    data: { name: 'swish' }
  },
  {
    path: 'tack/:hash',
    component: ThankYouPageComponent,
    data: { name: 'thankyou' }
  },
  {
    path: 'test',
    component: TestPageComponent,
    data: { name: 'test' }
  },
  { path: ':slug', component: ContentfulResolverComponent }
];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes, {
    relativeLinkResolution: 'legacy'
})
  ]
})
export class AppRoutingModule { }
