<div class="filter" #element [class.filter--error]="isError">
  <form class="filter__form" [formGroup]="form">
    <div class="filter__top">
      <div class="filter__top__container container">
        <p class="filter__top__error" *ngIf="isError">
          <strong>Fel!</strong> Vi kunde inte hämta filter, vänligen försök igen om en liten stund.
        </p>

        <fieldset class="filter__tags" *ngIf="categoryControls.length">
          <label *ngFor="let category of categoryControls; let i = index">
            <input type="checkbox" [formControl]="category" value="{{ categories[i].value }}" (change)="onChange()" />
            <span class="filter__tags__icon">
              {{ categories[i].label }}
            </span>
          </label>
        </fieldset>
      </div>
    </div>
  </form>
</div>
