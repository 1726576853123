<div class="products">
  <div class="products__container container">
    <h1 class="products__title" [class.products__title--small]="isStartpage">{{ title || 'Produkter' }}</h1>
    <p class="products__text" *ngIf="text" [innerHtml]="text"></p>
    <div class="products__grid">
      <div class="products__grid__wrapper products__grid__wrapper--message" *ngIf="error || isLoading || !products.length">
        <div class="loader" *ngIf="isLoading"></div>
        <div class="products__error alert alert-secondary" role="alert" *ngIf="error">
          Vi kunde tyvärr inte hämta produkter. Vänligen försök igen om en liten stund. Felkod: {{ error }}
        </div>
        <div class="products__error alert alert-secondary" role="alert" *ngIf="!error && !isLoading && !products.length">
          Vi kunde inte hitta några produkter baserat på dina valda filter.
        </div>
      </div>

      <div class="products__grid__wrapper" *ngIf="!error && !isLoading && products.length">
        <div *ngFor="let item of products; trackBy: trackById" class="products__item">
          <a [routerLink]="['/produkt', item.id]" class="products__item__link">
            <span class="products__item__bg">
              <span class="products__item__overlay">
                <div class="products__item__overlay__wrapper">
                  <span class="products__item__overlay__title">{{ item.name }}</span>
                  <span class="products__item__overlay__price">
                    {{ item.type === 'range' ? 'fr.' : '' }} {{ item.total | price }}&nbsp;kr
                  </span>
                  <span class="products__item__overlay__btn btn btn-primary">Köp här</span>
                </div>
              </span>

              <span class="products__item__bg__image" [ngStyle]="{ 'background-image': getFirstImage(item.images) }"></span>
            </span>

            <span class="products__item__categories">
              <span *ngFor="let name of getCategoriesArray(item.categoryNames)">{{ name }}</span>
            </span>

            <span class="products__item__price">
              <span class="products__item__price__title">{{ item.name }}</span>
              <span class="products__item__price__value"> {{ item.type === 'range' ? 'fr.' : '' }} {{ item.total | price }}&nbsp;kr </span>
            </span>
          </a>
        </div>
      </div>
    </div>
    <div class="products__footertext" *ngIf="footerText" [innerHtml]="footerText"></div>
  </div>
</div>
