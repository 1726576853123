import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ContentfulService } from 'src/app/services/contentful.service';
import { Asset, Entry } from 'contentful';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-start-page',
  templateUrl: './startpage.component.html',
  styleUrls: ['./startpage.component.scss']
})
export class StartPageComponent implements OnInit, OnDestroy {
  @ViewChild('slider') slider: ElementRef;

  error: any;
  isLoading: boolean;

  data: Entry<any>;

  footerAsset: Asset;
  slides: Array<Entry<any>> = [];
  categoryAssets: Array<Asset> = [];
  articles: Array<Entry<any>> = [];

  private ngUnsubscribe$ = new Subject<void>();

  constructor(
    private contentful: ContentfulService,
    private seoService: SeoService,
  ) { }

  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  trackBySysId = (index, entry: Entry<any>) => entry.sys.id;

  private loadData() {
    this.isLoading = true;

    this.contentful.getEntries<any>({ include: 3, content_type: 'startpage' }).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(response => {
      this.isLoading = false;

      this.data = response.items[0];
      this.slides = this.data.fields.slides;
      this.articles = this.data.fields.articles;
      this.footerAsset = this.data.fields.footerImage;
      this.categoryAssets = this.data.fields.categoryImages;

      this.seoService.setMeta(this.data.fields.metaTitle, this.data.fields.metaDescription);
    }, () => {
      this.isLoading = false;
      this.error = true;
    });
  }
}
