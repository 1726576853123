<div #element class="hero owl-carousel" [ngClass]="{ 'hero--dark': isDark, 'hero--loading': isLoading }">
  <div class="hero__slide hero__slide--loader" *ngIf="isLoading">
    <div class="loader loader--no-delay"></div>
  </div>

  <div
    *ngFor="let slide of slides"
    class="hero__slide hero__slide--{{ slide.fields.template?.toLowerCase() || 'primary' }}"
    [class.hero__slide--dark]="slide.fields?.darkLogo"
  >
    <div class="hero__slide__container container">
      <div class="hero__slide__content">
        <app-logo class="hero__slide__logo" *ngIf="['tertiary', 'primary'].includes(slide.fields.template.toLowerCase())"></app-logo>
        <h1 class="hero__slide__title">{{ slide.fields.title }}</h1>
        <p class="hero__slide__text">{{ slide.fields.text }}</p>
        <a *ngIf="slide.fields.link" [href]="slide.fields.link.fields.url" class="hero__slide__button btn btn-primary btn-lg">
          {{ slide.fields.link.fields.title }}
        </a>
      </div>
    </div>
    <div class="hero__slide__bg" [style.backgroundImage]="'url(' + slide.fields?.image.fields.file.url + ')'">
      <img [src]="slide.fields?.image.fields.file.url" [alt]="slide.fields?.image.fields.title" />
    </div>
  </div>
</div>
