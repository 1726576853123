import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { ApiService } from './services/api.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(public apiService: ApiService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('api/logs')) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError(error => {
        this.apiService.log('HTTP call failed : ' + JSON.stringify(error), window.location.href, 'warning').subscribe();
        return throwError(error);
      })
    );
  }
}
