import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input } from '@angular/core';
import { HeroService } from '../../services/hero.service';
import { MenuComponent } from '../menu/menu.component';
import { CartService } from 'src/app/services/cart.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() isMinimized: boolean;
  @Input() showCart: boolean = true;

  @ViewChild('element', { static: true }) element: ElementRef;
  @ViewChild('menu', { static: true }) menu: MenuComponent;
  @ViewChild('cart', { static: true }) cart: MenuComponent;

  isOpen: boolean;
  isDark: boolean;
  isStatic: boolean;
  hasLogo: boolean = true;
  nrOfItems: number = 0;
  noAnimation: boolean = true;

  subscriptions: Subscription[] = [];

  constructor(private heroService: HeroService, private cartService: CartService) {
    this.isDark = this.heroService.getDark();
    this.hasLogo = this.heroService.getMode() !== 'tertiary';
    this.nrOfItems = this.cartService.quantity();
    
    this.subscriptions.push(
      this.heroService.observeDark().subscribe(dark => this.isDark = dark)
    );

    this.subscriptions.push(
      this.heroService.observeMode().subscribe(mode => this.hasLogo = mode !== 'tertiary')
    );

    this.subscriptions.push(
      this.cartService.observe().subscribe(() => this.nrOfItems = this.cartService.quantity())
    );
  }

  ngOnInit() {
    if (!this.isMinimized) {
      this.watch();
      window.addEventListener('scroll', this.watch);
    } else {
      this.isStatic = true;
    }
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.watch);
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  openMenu = () => {
    this.menu.open();
  }

  openCart = () => {
    this.cart.open();
  }

  watch = () => {
    const maxScroll = 30;
    const percent = Math.min(window.pageYOffset / maxScroll, 1);
    this.isMinimized = percent === 1;

    setTimeout(() => this.noAnimation = false, 250);
  }
}
