// Fallback storage for e.g. iOS5+ private mode

export default class FallbackStorage {
  private data = [];
  public length = 0;

  setItem = (key, value) => {
    this.removeItem(key);
    this.data.push({ key, value: value.toString() });
    this.length = this.data.length;
  };

  getItem = key => {
    const cache = this.data.find(value => value.key === key);
    return cache ? cache.value : null;
  };

  removeItem = key => {
    this.data = this.data.filter(value => value.key !== key);
    this.length = this.data.length;
  };

  clear = () => {
    this.data = [];
    this.length = this.data.length;
  };
  
  key = index => this.data.length > index ? this.data[index].key : null;
}
