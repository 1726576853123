import { Component, OnInit, ViewChild, ElementRef, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContentfulService } from 'src/app/services/contentful.service';
import { ApiService } from "../../services/api.service";
import { NavService } from "../../services/nav.service";
import { MenuItem } from './menu.interface';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent implements OnInit, OnDestroy {
  @ViewChild('content', { static: true }) content: ElementRef;

  isOpen: boolean;

  mainItems: Array<MenuItem> = [];
  footerItems: Array<MenuItem> = [];

  footerTitle: string;

  scrollbars: any;

  constructor(
    private contentful: ContentfulService,
    private cd: ChangeDetectorRef,
    private api: ApiService,
    private navService: NavService
  ) { }

  ngOnInit() {
    this.scrollbars = OverlayScrollbars(this.content.nativeElement, {
      className: 'os-theme-dark',
      sizeAutoCapable: true,
      paddingAbsolute: true,
      scrollbars: {
        autoHide: 'leave',
        autoHideDelay: 100
      }
    });

    this.loadData();
  }

  ngOnDestroy() {
    this.scrollbars.destroy();
  }

  open() {
    this.isOpen = true;
    this.cd.markForCheck();
  }
  close() {
    this.isOpen = false;
  }

  private loadData() {
    forkJoin([
      this.api.getCategories({ group: 1 }),
      this.contentful.getEntries<any>({
        'fields.slug[in]': 'main,contact',
        'content_type': 'navigation'
      })
    ]).subscribe(([categories, contentful]) => {
      contentful.items.forEach(({ fields }) => {
        const items = fields.items?.map((item) => ({
          title: item.fields?.title,
          url: item.fields?.url || '/' + item.fields?.slug,
          target: item.fields?.blank ? '_blank' : '_self'
        })) || [];

        if (fields.slug === 'main') {
          this.mainItems = items;
        } else if (fields.slug === 'contact') {
          this.footerItems = items;
          this.footerTitle = fields.title;
        }
      });

      const categoryItems = categories.items.map(item => ({
        title: item.name,
        url: `/blommor/${this.navService.sanitize(item.name)}`,
        target: '_self'
      }));

      this.mainItems = [...categoryItems, ...this.mainItems];
      this.cd.markForCheck();
    });
  }
}
