import { Injectable } from '@angular/core';
import Cache from './cache';

@Injectable({
  providedIn: 'root'
})
export class MemoryCacheService extends Cache {
  constructor() { 
    super('memory');
  }
}
