<section class="notfound">
  <app-header isMinimized="true" class="notfound__header"></app-header>
  <div class="notfound__wrapper">
    <div class="notfound__slider">
      <div class="notfound__slider__item">
        <div class="notfound__slider__item__container container">
          <h1 class="notfound__slider__item__title">Vi ber om ursäkt!</h1>
          <p class="notfound__slider__item__text">
            Vi kan inte hitta sidan du letade efter.
          </p>
          <br />
          <a href="/" class="notfound__btn btn btn-primary btn-lg"
            >Gå till startsidan</a
          >
        </div>
      </div>
    </div>
  </div>
  <app-footer class="notfound__footer"></app-footer>
</section>
