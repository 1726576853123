<section class="checkout">
  <div class="checkout__container container">
    <button type="button" class="checkout__close btn-close hamburger hamburger--slider is-active" (click)="close()">
      <span class="hamburger-box"> <span class="hamburger-inner"></span> </span>
    </button>

    <div class="text-center">
      <app-logo class="checkout__logo" [isDark]="true" xsize="small"></app-logo>
    </div>

    <div class="row">
      <div class="col-md-8">
        <h2 class="checkout__title mb-5 mt-0">
          Slutför din beställning
          <span class="checkout__title__steps">Steg {{ step + 1 }} av {{ maxStep + 1 }}</span>
        </h2>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 order-md-2 mb-4">
        <h4 class="d-flex justify-content-between align-items-center mb-3">Varukorg</h4>
        <div #scrollcontent class="checkout__cart">
          <p class="checkout__cart__empty" *ngIf="!products.length">Din varukorg är tom</p>
          <app-cart-list></app-cart-list>
        </div>
        <div class="checkout__cart__footer" *ngIf="products.length">
          <table class="checkout__cart__price">
            <tr *ngIf="getShipping()">
              <td>{{ getShippingTitle() }}</td>
              <td>{{ getShipping() | price }}&nbsp;kr</td>
            </tr>
            <tr class="checkout__cart__price__total">
              <td>
                Totalt inkl moms
                <span class="checkout__cart__price__note" *ngIf="!getShipping()">Exklusive leveransavgift</span>
              </td>
              <td>{{ getTotal(true) | price }}&nbsp;kr</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="col-md-8 order-md-1">
        <form class="checkout__form" [formGroup]="form" (ngSubmit)="nextStep()" [ngClass]="{ submitted: submitted }">
          <div class="checkout__step mb-3" [hidden]="step != 0" formGroupName="step0">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group custom-control custom-control-lg custom-radio" style="float: left; margin-right: 20px">
                  <input
                    type="radio"
                    class="custom-control-input"
                    formControlName="type"
                    value="general"
                    id="typegeneral"
                    (change)="onTypeChange()"
                  />
                  <label class="custom-control-label" for="typegeneral">Normalorder</label>
                </div>
                <div class="form-group custom-control custom-control-lg custom-radio" style="float: left">
                  <input
                    type="radio"
                    class="custom-control-input"
                    formControlName="type"
                    value="funeral"
                    id="typefuneral"
                    (change)="onTypeChange()"
                  />
                  <label class="custom-control-label" for="typefuneral">Begravningsorder</label>
                </div>
              </div>
            </div>
            <hr class="mb-4" />

            <h4 class="mb-3">{{ isFuneral ? 'Leveransdag och tid' : 'Leveransdag' }}</h4>
            <div class="row">
              <div class="col">
                <div class="checkout__calendar calendar" [ngClass]="{ submitted: submitted }">
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        type="tel"
                        class="form-control form-control-lg"
                        placeholder="åååå-mm-dd"
                        readonly
                        formControlName="delivery_at"
                        id="delivery_at"
                        [value]="getDate() | date: 'EEEE, dd MMMM, yyyy' | titlecase"
                        (focus)="toggleCalendar(true)"
                        (click)="stopPropagation($event)"
                        (touchstart)="stopPropagation($event)"
                        [class.ng-invalid]="isDateError"
                      />

                      <ngb-datepicker
                        #date
                        [hidden]="!isCalendarOpen"
                        class="delivery__calendar__picker dropdown-menu show"
                        (dateSelect)="onDateSelect($event)"
                        navigation="arrows"
                        [minDate]="minDate"
                        [maxDate]="maxDate"
                        (click)="stopPropagation($event)"
                        (touchstart)="stopPropagation($event)"
                      >
                      </ngb-datepicker>

                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary checkout__calendar__button" (click)="toggleCalendar()" type="button">
                          <i class="icomoon icomoon-calendar"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto checkout__time" *ngIf="isFuneral">
                <div class="dropdown checkout__time__dropdown">
                  <button
                    class="btn btn-outline-secondary dropdown-toggle checkout__time__dropdown__button"
                    type="button"
                    id="dropdownMenuButton"
                    [ngClass]="{ 'is-invalid': isFuneralTimeError }"
                    (click)="toggleFuneralTime()"
                  >
                    {{ selectedFuneralTime }}
                  </button>
                  <div class="dropdown-menu dropdown-menu-right" [ngClass]="{ show: isFuneralTimeOpen }">
                    <button type="button" class="dropdown-item" *ngFor="let time of funeralTimes" (click)="setFuneralTime(time)">
                      {{ time }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr class="mb-4" />

            <h4 class="mb-3">
              {{ isFuneral ? 'Mottagare / Bår' : 'Mottagare' }}
            </h4>
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="receiver_firstname">Förnamn</label>
                <input type="text" class="form-control form-control-lg" formControlName="receiver_firstname" id="receiver_firstname" />
                <div class="invalid-feedback" *ngIf="step0.get('receiver_firstname').hasError('pattern')">
                  Namnet får endast innehålla bokstäver, siffror och bindestreck.
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="receiver_lastname">Efternamn</label>
                <div class="input-group">
                  <input type="text" class="form-control form-control-lg" formControlName="receiver_lastname" id="receiver_lastname" />
                  <div class="input-group-append" *ngIf="!isFuneral">
                    <button
                      title="Hitta adress via förnamn och efternamn"
                      type="button"
                      class="checkout__hitta-button btn btn-lg btn-outline-secondary"
                      [disabled]="!isNameValid()"
                      (click)="findReceiverAddress('name')"
                    >
                      <img src="/assets/images/{{ isNameValid() ? 'hitta.se.svg' : 'hitta.se.grey.svg' }}" alt="Hitta.se" />
                    </button>
                  </div>

                  <div class="invalid-feedback" *ngIf="step0.get('receiver_lastname').hasError('pattern')">
                    Efternamnet får endast innehålla bokstäver, siffror och bindestreck.
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="!isFuneral">
              <div class="col-md-12 mb-3">
                <label for="receiver_phone">Telefon</label>
                <div class="input-group">
                  <input type="tel" class="form-control form-control-lg" formControlName="receiver_phone" id="receiver_phone" />
                  <div class="input-group-append">
                    <button
                      title="Hitta adress via telefonnummer"
                      type="button"
                      class="checkout__hitta-button btn btn-lg btn-outline-secondary"
                      [disabled]="!isPhoneValid()"
                      (click)="findReceiverAddress('phone')"
                    >
                      <img src="/assets/images/{{ isPhoneValid() ? 'hitta.se.svg' : 'hitta.se.grey.svg' }}" alt="Hitta.se" />
                    </button>
                  </div>
                  <div class="invalid-feedback" *ngIf="step0.get('receiver_phone').hasError('pattern')">
                    Telefon får endast innehålla siffror, bindestreck och plustecken.
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="isFuneral">
              <div class="col-md-12 mb-3">
                <label for="receiver_church">Kyrka / Kapell</label>
                <input type="text" class="form-control form-control-lg" formControlName="receiver_church" id="receiver_church" />
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mb-3">
                <label for="receiver_address">Gatuadress</label>
                <input type="text" class="form-control form-control-lg" formControlName="receiver_address" id="receiver_address" />
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="receiver_zip">Postnummer</label>
                <input
                  type="tel"
                  class="form-control form-control-lg"
                  formControlName="receiver_zip"
                  id="receiver_zip"
                  maxlength="6"
                  [ngClass]="{ 'is-invalid': isZipError }"
                />
              </div>

              <div class="col-md-6 mb-3">
                <label for="receiver_city">Ort</label>
                <input
                  type="text"
                  class="form-control form-control-lg"
                  formControlName="receiver_city"
                  id="receiver_city"
                  [ngClass]="{ 'is-invalid': isZipError }"
                />
              </div>
            </div>
            <hr class="mb-4" />

            <h4 class="mb-3">Hälsningstext</h4>

            <div class="row" formGroupName="meta">
              <div class="col-12">
                <div class="form-group">
                  <textarea class="form-control form-control-lg" formControlName="message" rows="3" maxlength="255"></textarea>
                  <label>Max 255 tecken</label>
                </div>
              </div>
            </div>
          </div>

          <div class="checkout__step mb-3" [hidden]="step != 1" formGroupName="step1">
            <h4 class="mb-3">Avsändare</h4>
            <div class="row">
              <div class="col-md-12 mb-3">
                <label for="sender_name">Namn</label>
                <input type="text" class="form-control form-control-lg" formControlName="sender_name" id="sender_name" />
                <div class="invalid-feedback" *ngIf="step1.get('sender_name').hasError('pattern')">
                  Namnet får endast innehålla bokstäver, siffror och bindestreck.
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="sender_phone">Telefon</label>
                <input type="tel" class="form-control form-control-lg" formControlName="sender_phone" id="sender_phone" />
                <div class="invalid-feedback" *ngIf="step1.get('sender_phone').hasError('pattern')">
                  Telefon får endast innehålla siffror, bindestreck och plustecken.
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="sender_email">E-post</label>
                <input type="email" class="form-control form-control-lg" formControlName="sender_email" id="sender_email" />
              </div>
            </div>
            <hr class="mb-4" />

            <h4 class="mb-3">Övrigt</h4>
            <div class="row" formGroupName="meta">
              <div class="col-12">
                <div class="form-group">
                  <label for="other"> Meddelande till butiken, exempelvis portkod eller annat som kan underlätta leveransen. </label>
                  <textarea class="form-control form-control-lg" formControlName="other" rows="3" maxlength="1000"></textarea>
                </div>
              </div>
            </div>
            <hr class="mb-4" />
          </div>

          <div class="checkout__step mb-5" [hidden]="step != 2" formGroupName="step2">
            <h4 class="mb-3">{{ isFuneral ? 'Leveransdag och tid' : 'Leveransdag' }}</h4>
            <div class="row">
              <div class="col-md-12 mb-3 ucfirst">
                {{ getDate() | date: 'EEEE, dd MMMM, yyyy' | titlecase }}
                <span *ngIf="isFuneral">kl. {{ selectedFuneralTime }}</span>
              </div>
            </div>
            <hr class="mb-4" />

            <div class="row">
              <div class="col-sm-6 mb-3">
                <h4 class="mb-3">{{ isFuneral ? 'Mottagare / Bår' : 'Mottagare' }}</h4>
                {{ form.value.step0.receiver_firstname }} {{ form.value.step0.receiver_lastname }}<br />
                <span *ngIf="isFuneral">{{ form.value.step0.receiver_church }}<br /></span>
                {{ form.value.step0.receiver_address }}<br />
                {{ form.value.step0.receiver_zip }} {{ form.value.step0.receiver_city }}
                <span *ngIf="!isFuneral"><br />Telefon: {{ form.value.step0.receiver_phone }}</span>
              </div>
              <div class="col-sm-6 mb-3">
                <h4 class="mb-3">Avsändare</h4>
                {{ form.value.step1.sender_name }}<br />
                {{ form.value.step1.sender_phone }}<br />
                {{ form.value.step1.sender_email }}
              </div>
            </div>
            <hr class="mb-4" />

            <div class="row">
              <div class="col-12 col-sm-6 mb-3" *ngIf="form.value.step0.meta.message">
                <h4 class="mb-3">Hälsningstext</h4>
                {{ form.value.step0.meta.message }}
                <hr class="mb-3 mt-4 d-sm-none" *ngIf="form.value.step1.meta.other" />
              </div>
              <div class="col-12 col-sm-6 mb-3" *ngIf="form.value.step1.meta.other">
                <h4 class="mb-3">Övrigt</h4>
                {{ form.value.step1.meta.other }}
              </div>
            </div>
            <hr class="mb-4" *ngIf="form.value.step0.meta.message || form.value.step1.meta.other" />

            <div *ngIf="showStores">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <h4 class="mb-3">Butik och leveranssätt</h4>
                  <select formControlName="store" class="custom-select custom-select-lg" (change)="onStoreChange()">
                    <option [value]="null" selected>Välj butik</option>
                    <option *ngFor="let store of stores" [ngValue]="store">{{ store.name }}</option>
                  </select>
                </div>
              </div>

              <div class="row" *ngIf="form.value.step2.store">
                <div class="col-md-12 mb-3">
                  <h5 class="mb-3">Leveranssätt</h5>
                  <div class="checkout__shipping">
                    <div
                      class="checkout__shipping__item custom-control custom-control-md custom-radio"
                      *ngFor="let shipping of form.value.step2.store.shipping; let i = index"
                    >
                      <input
                        type="radio"
                        id="shippingRadio{{ i }}"
                        formControlName="shipping"
                        [value]="shipping"
                        class="custom-control-input"
                        (change)="onShippingChange()"
                      />
                      <label class="custom-control-label" for="shippingRadio{{ i }}"
                        >{{ shipping.name }} {{ shipping.charge.total | number }}:-</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <hr class="mb-4" />
            </div>

            <h4 class="mb-3">Betalssätt</h4>
            <div class="row">
              <div class="col-12">
                <div class="custom-control custom-radio custom-radio-payment">
                  <input id="swish" formControlName="payment" type="radio" class="custom-control-input" value="swish" />
                  <label class="custom-control-label" for="swish">Swish</label>
                  <a
                    href="https://www.getswish.se"
                    title="Läs mer om Swish"
                    class="custom-radio-payment-background custom-radio-payment-background--swish"
                    target="_blank"
                    >Swish</a
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="custom-control custom-radio custom-radio-payment">
                  <input id="card" formControlName="payment" type="radio" class="custom-control-input" value="card" />
                  <label class="custom-control-label" for="card">Kortbetalning</label>
                  <a
                    href="https://www.swedbankpay.se"
                    title="Läs mer om Swedbank Pay"
                    class="custom-radio-payment-background custom-radio-payment-background--card"
                    target="_blank"
                    >Payex</a
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="alert alert-danger" role="alert" *ngIf="error === true">
            Vi kunde tyvärr inte spara din beställning. Vänligen försök igen om en liten stund.
          </div>

          <div class="alert alert-danger" role="alert" *ngIf="error === 'validation'">
            Vänligen kontrollera att samtliga fält är korrekt ifyllda.
          </div>

          <div class="alert alert-danger" role="alert" *ngIf="isDeliveryError">
            Vi kan tyvärr inte leverera till angivet postnummer och valt datum. Vänligen försök igen med ett annat datum eller postnummer.
          </div>

          <div class="alert alert-danger" role="alert" *ngIf="isServerError">
            Ajdå! Vi kunde inte anropa servern för leveranser. Vänligen försök igen om en liten stund.
          </div>

          <button class="btn btn-primary btn-lg btn-block" type="submit" [disabled]="isLoading || !products.length" *ngIf="step < 2">
            <span *ngIf="products.length">{{ isLoading ? 'Vänligen vänta...' : 'Nästa' }}</span>
            <span *ngIf="!products.length">Din varukorg är tom</span>
          </button>

          <button class="btn btn-primary btn-lg btn-block" type="submit" [disabled]="isLoading || !products.length" *ngIf="step == 2">
            <span *ngIf="products.length">{{ isLoading ? 'Vänligen vänta...' : 'Betala' }}</span>
            <span *ngIf="!products.length">Din varukorg är tom</span>
          </button>

          <button
            class="btn btn-link btn-block"
            type="button"
            (click)="previousStep()"
            [disabled]="isLoading"
            *ngIf="step > 0 && products.length"
          >
            Tillbaka
          </button>
        </form>
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>
