import OrderItem from './order-item.model';
import { IPriceRange } from './price-range.interface';

export default class Product {
  public id: number;
  public type?: string;
  public date: string;
  public enabled?: boolean;
  public name: string;
  public description?: string;

  public quantity = 1;

  public price: number | IPriceRange;
  public vat: number;
  public tax?: string;
  public total: number;

  public attributes: any[] = [];
  public categories?: any[] = [];
  public images: any[] = [];
  public addons: Product[] = [];

  public static createFromOrderItem(item: OrderItem): Product {
    return {
      id: item.infrasec_id,
      date: item.created_at,
      name: item.name,
      type: item.type,
      price: item.price,
      vat: item.vat,
      total: item.price + item.vat,
      attributes: item.meta,
      quantity: item.quantity,
      images: item.images || [],
      addons: item.addons
        ? item.addons.map(addon => this.createFromOrderItem(addon))
        : []
    };
  }
}
