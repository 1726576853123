<nav class="menu" [class.menu--open]="isOpen">
  <div class="menu__overlay" (click)="close()"></div>
  <div class="menu__wrapper">
    <div class="menu__header">
      <app-logo class="menu__header__logo" size="small" url=""></app-logo>
      <button type="button" class="menu__header__close hamburger hamburger--slider is-active" (click)="close()">
        <span class="hamburger-box"> <span class="hamburger-inner"></span> </span>
      </button>
    </div>
    <div class="menu__content">
      <ul #content>
        <li *ngFor="let item of mainItems">
          <a [href]="item.url" [target]="item.target">{{ item.title }}</a>
        </li>
      </ul>
    </div>
    <div class="menu__footer">
      {{ footerTitle }}<br />
      <a *ngFor="let item of footerItems" [href]="item.url" [target]="item.target">{{ item.title }}</a>
    </div>
  </div>
</nav>
