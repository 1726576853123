import { Component, Input, OnDestroy } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import Product from 'src/app/models/product.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cart-list',
  templateUrl: './cart-list.component.html',
  styleUrls: ['./cart-list.component.scss']
})
export class CartListComponent implements OnDestroy {
  @Input() products: Product[];
  @Input() readonly: boolean;

  subscriptions: Subscription[] = [];

  constructor(private cartService: CartService) {
    if (this.products === undefined) {
      this.products = this.cartService.items();
    }
    this.subscriptions.push(
      this.cartService.observe().subscribe(cart => this.products = cart.products)
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  remove = (product: Product, addon: Product = null) => this.cartService.remove(product, addon);
  increase = (item: Product) => this.cartService.increase(item);
  decrease = (item: Product) => this.cartService.decrease(item);
  
  getFirstImage = (images: any[], size: string = 'small') => images.length && images[0][size] ? images[0][size] : null;

  getAttributeValue = attribute => {
    const maxlength = 15;
    if (attribute) {
      const value = attribute.value;
      return value ? value.length > maxlength ? `${value.substring(0, maxlength).trim()}...` : value : null;
    }
    return null;
  }
}
