import {
  Component,
  ViewChild,
  ElementRef,
  OnDestroy,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from "@angular/core";
import { HeroService } from "../../services/hero.service";
import { Subject } from "rxjs";
import { Entry } from "contentful";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-hero",
  templateUrl: "./hero.component.html",
  styleUrls: ["./hero.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeroComponent implements OnDestroy {
  @ViewChild("element", { static: true }) element: ElementRef;

  @Input("isLoading") isLoading: boolean;

  @Input("slides")
  get slides(): Array<Entry<any>> { return this._slides; }
  set slides(slides: Array<Entry<any>>) {
    this._slides = slides;
    if (this._slides.length) {
      this.cd.markForCheck();
      setTimeout(() => this.initSlider(), 100);
    }
  }

  isDark: boolean;
  startPosition: number;

  private $owl: any;
  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  private _slides: Array<Entry<any>>;

  constructor(private heroService: HeroService, private cd: ChangeDetectorRef) {
    this.isDark = this.heroService.getDark();
    this.heroService
      .observeDark()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(dark => (this.isDark = dark));
  }


  ngOnDestroy() {
    if (this.$owl) {
      this.$owl.remove();
    }
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  private initSlider() {
    if (!this.element) {
      return;
    }

    if (this.$owl) {
      this.$owl.remove();
    }

    this.$owl = $(this.element.nativeElement);

    this.$owl.on("initialized.owl.carousel changed.owl.carousel", event => {
      const slides = event.target.querySelectorAll(".hero__slide");
      const current = slides[event.item.index || 0];

      let mode = "primary";
      if (current.classList.contains("hero__slide--secondary")) {
        mode = "secondary";
      } else if (current.classList.contains("hero__slide--tertiary")) {
        mode = "tertiary";
      }

      const isDark = current.classList.contains("hero__slide--dark");

      this.heroService.setDark(isDark);
      this.heroService.setMode(mode);
    });

    this.$owl.owlCarousel({
      loop: true,
      margin: 0,
      dots: true,
      nav: false,
      items: 1,
      autoplay: window.location.href.indexOf("localhost") === -1,
      startPosition: this.startPosition ?? 0
    });
  }
}
