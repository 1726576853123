import { Injectable } from '@angular/core';
import { SessionCacheService } from './cache/session-cache.service';
import { Subject, Observable } from 'rxjs';
import Product from '../models/product.model';
import Store from '../models/store.model';
import Cart from '../models/cart.model';
import Shipping from '../models/shipping.model';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private cacheKey = 'cart';

  private cart: Cart = new Cart();
  private subject = new Subject<Cart>();

  constructor(
    private sessionCache: SessionCacheService,
    private datePipe: DatePipe
  ) {
    const data = this.sessionCache.get(this.cacheKey);
    if (data) {
      Object.keys(data).forEach(k => {
        this.cart[k] = data[k];
      });
    }
  }

  items = (): Product[] => this.cart.products;

  add(product: Product, quantity: number = 1, attributeValues: any[] = []) {
    // Create immutable clone
    const newProduct = Object.assign({ quantity: +quantity }, product);

    // Map attribute values from form to product attributes
    newProduct.attributes = attributeValues
      .map((attributeValue, index) => {
        if (attributeValue) {
          const attribute = product.attributes[index];
          if (attribute.type === 'option' && attributeValue) {
            return {
              name: attribute.description,
              ...attribute.values[0]
            };
          } else if (attribute.type === 'choice') {
            return {
              name: attribute.description,
              ...(attribute.values.find(o => o.id === attributeValue) || {})
            };
          } else if (attribute.type === 'text') {
            return {
              id: attribute.values[0].id,
              name: attribute.description,
              value: attributeValue
            };
          }
        }
      })
      .filter(value => value != null);

    // Check if item already exist in cart with no addons
    const existingItem =
      !newProduct.addons.length &&
      this.items().find(
        p =>
          !p.addons.length &&
          p.id === newProduct.id &&
          JSON.stringify(p.attributes) ===
            JSON.stringify(newProduct.attributes) &&
          !(newProduct.type === 'range' && p.total !== newProduct.total)
      );

    if (existingItem) {
      existingItem.quantity += +quantity;
    } else {
      this.cart.products.push(newProduct);
    }
    this.save(this.cart);
  }

  remove(product: Product, addon: Product = null): any {
    let removedItem, existing;

    // Addon
    if (addon) {
      existing = product.addons.findIndex(a => a === addon);
      removedItem =
        existing >= 0 ? product.addons.splice(existing, 1)[0] : null;
    }
    // Product
    else {
      existing = this.cart.products.findIndex(p => p === product);
      removedItem =
        existing >= 0 ? this.cart.products.splice(existing, 1)[0] : null;
    }

    // Save and broadcast change
    if (removedItem) {
      this.save(this.cart);
    }

    return removedItem;
  }

  increase(item: Product, step: number = 1): boolean {
    const nextValue = item.quantity + step;
    if (nextValue <= 99 && nextValue >= 0) {
      item.quantity += step;
      this.save(this.cart);
      return true;
    }
    return false;
  }

  clear = () => this.save((this.cart = new Cart()));

  decrease = (item: Product, step: number = 1) => this.increase(item, -step);
  observe = (): Observable<Cart> => this.subject.asObservable();

  total = (includeShipping: boolean = true) => this.cart.total(includeShipping);
  vat = () => this.cart.vat();

  quantity = () => {
    let total = 0;
    this.cart.products.forEach(p => {
      total += p.quantity;
      total += p.addons.length
        ? p.addons.map(a => a.quantity).reduce((p, c) => p + c)
        : 0;
    });
    return total;
  };

  store = (): Store => this.cart.store;
  shipping = (): Shipping => this.cart.shipping;
  zip = (): number => this.cart.delivery_zip;
  city = (): string => this.cart.delivery_city;
  date = (): string => (this.cart.delivery_at ? this.cart.delivery_at : null);
  time = (): string =>
    this.cart.delivery_time ? this.cart.delivery_time : null;

  setProducts(products: Product[]) {
    this.cart.products = products;
  }

  setStore = (store: Store) => {
    this.cart.store = store;
    this.save(this.cart);
  };

  setShipping = (shipping: Shipping) => {
    this.cart.shipping = shipping;
    // this.save(this.cart);
  };

  setDeliveryDate = (date: string) => {
    this.cart.delivery_at = this.datePipe.transform(
      new Date(date),
      'yyyy-MM-dd'
    );
    // this.save(this.cart);
  };

  setDeliveryTime = (time: string) => {
    this.cart.delivery_time = time;
    // this.save(this.cart);
  };

  setDeliveryZip = (zip: number) => {
    this.cart.delivery_zip = zip;
    // this.save(this.cart);
  };

  setDeliveryCity = (city: string) => {
    this.cart.delivery_city = city;
    // this.save(this.cart);
  };

  private save(cart: Cart) {
    this.sessionCache.set(this.cacheKey, cart);
    this.subject.next(cart);
  }
}
