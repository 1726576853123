import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy, AfterViewInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-hitta.se',
  templateUrl: './hitta.se.component.html',
  styleUrls: ['./hitta.se.component.scss'],
  // Manually repaint changes due to scrollbars constant repaint
  changeDetection: ChangeDetectionStrategy.OnPush 
})
export class HittaComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('scrollcontent', { static: true }) scrollcontent: ElementRef;

  isLoading: boolean;
  error: any;

  items: [] = [];
  form: FormGroup;
  scrollbars: any;

  constructor(
    public modal: NgbActiveModal,
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() {
    this.scrollbars.destroy();
  }

  ngAfterViewInit() {
    this.initScroll();
  }

  initForm() {
    this.form = this.fb.group({
      address: ['', [Validators.required]]
    });
  }

  initScroll() {
    this.scrollbars = OverlayScrollbars(this.scrollcontent.nativeElement, {
      className       : "os-theme-dark",
      sizeAutoCapable : true,
      paddingAbsolute : true,
      scrollbars: {
        autoHide: 'leave',
        autoHideDelay: 100
      }
    });
  }

  loadData(query: string) {
    this.isLoading = true;

    this.apiService.searchHitta(query).subscribe(items => {
      this.isLoading = false;
      this.items = items;
      this.cdr.markForCheck();
    }, response => {
      this.isLoading = false;
      this.error = response.error;
      this.cdr.markForCheck();
    });

    this.cdr.markForCheck();
  }

  cancel() {
    this.modal.dismiss();
  }

  confirm() {
    this.modal.close(this.form.value.address);
  }


}
