<ul class="cartlist" *ngIf="products && products.length">
  <li class="cartlist__item" *ngFor="let product of products">
    <!-- PRODUCT -->
    <span class="cartlist__item__wrapper">
      <span class="cartlist__item__left">
        <span class="cartlist__item__image">
          <span class="cartlist__item__image__bg" *ngIf="product.images.length" [ngStyle]="{'background-image': 'url(' + getFirstImage(product.images) + ')'}"></span>
          <span class="cartlist__item__image__label" *ngIf="!product.images.length">Bild saknas</span>
        </span>
      </span>

      <span class="cartlist__item__middle">
        <span class="cartlist__item__title" *ngIf="readonly">{{product.name}}</span>
        <a class="cartlist__item__title" routerLink="/produkt/{{product.id}}" *ngIf="!readonly">{{product.name}}</a><br />
        <span class="cartlist__item__price">{{product.total | price}}&nbsp;kr</span>
        <p class="cartlist__attributes" *ngIf="product.attributes.length">
          <span *ngFor="let attribute of product.attributes">
            {{getAttributeValue(attribute, product.attributes)}}
          </span>
        </p>
      </span>
      <span class="cartlist__item__right">
        <span class="cartlist__item__quantity">
          <span class="cartlist__item__number">{{product.quantity}} ST</span>
          <button type="button" class="cartlist__item__count cartlist__item__count--up" (click)="increase(product)" *ngIf="!readonly"><i class="icomoon icomoon-chevron-up"></i></button>
          <button type="button" class="cartlist__item__count cartlist__item__count--down" (click)="decrease(product)" *ngIf="!readonly" [disabled]="product.quantity == 1"><i class="icomoon icomoon-chevron-down"></i></button>
        </span>
        <button type="button" class="cartlist__item__remove" (click)="remove(product)" *ngIf="!readonly">Ta bort</button>
      </span>
    </span>
    <!-- END! PRODUCT -->
    
    <!-- ADDONS -->
    <ul class="cartlist__addons" *ngIf="product.addons.length">
      <li class="cartlist__item" *ngFor="let addon of product.addons">
        <span class="cartlist__item__wrapper">
          <span class="cartlist__item__left">
            <span class="cartlist__item__image">
              <span class="cartlist__item__image__bg" *ngIf="addon.images.length" [ngStyle]="{'background-image': 'url(' + getFirstImage(addon.images) + ')'}"></span>
              <span class="cartlist__item__image__label" *ngIf="!addon.images.length">Bild saknas</span>
            </span>
          </span>
          <span class="cartlist__item__middle">
            <span class="cartlist__item__title" *ngIf="readonly">{{addon.name}}</span>
            <a class="cartlist__item__title" routerLink="/produkt/{{addon.id}}" *ngIf="!readonly">{{addon.name}}</a><br />
            <span class="cartlist__item__price">{{addon.total | price}}&nbsp;kr</span>
          </span>
          <span class="cartlist__item__right">
            <span class="cartlist__item__quantity">
              <span class="cartlist__item__number">{{addon.quantity}} ST</span>
              <button type="button" class="cartlist__item__count cartlist__item__count--up" (click)="increase(addon)" *ngIf="!readonly"><i class="icomoon icomoon-chevron-up"></i></button>
              <button type="button" class="cartlist__item__count cartlist__item__count--down" (click)="decrease(addon)" *ngIf="!readonly" [disabled]="addon.quantity == 1"><i class="icomoon icomoon-chevron-down"></i></button>
            </span>
            <button type="button" class="cartlist__item__remove" (click)="remove(product, addon)" *ngIf="!readonly">Ta bort</button>
          </span>
        </span>
      </li>
    </ul>
    <!-- END! ADDONS -->
  </li>
</ul>