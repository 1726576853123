<section class="product" [ngClass]="{ 'product--loading': isLoading }">
  <div class="product__container container">
    <button type="button" class="btn-close hamburger hamburger--slider is-active" (click)="close()">
      <span class="hamburger-box"> <span class="hamburger-inner"></span> </span>
    </button>

    <div class="product__loader">
      <div class="loader loader--no-delay"></div>
    </div>

    <div class="product__content">
      <div class="product__header product__header--mobile">
        <h1 class="product__title">{{ product.name }}</h1>
        <strong class="product__price">{{ product.total || 0 | price }}&nbsp;kr</strong>
      </div>

      <div #slider class="product__slider owl-carousel owl-carousel--dark-dots">
        <div class="product__slider__image" *ngIf="!product.images.length">Bild saknas</div>

        <div *ngFor="let image of product.images" class="product__slider__image">
          <div class="product__slider__image__bg" [ngStyle]="{ 'background-image': 'url(' + image.large + ')' }"></div>
        </div>
      </div>

      <div class="product__details">
        <div class="product__header product__header--desktop">
          <h1 class="product__title">{{ product.name }}</h1>
          <strong class="product__price">{{ product.total || 0 | price }}&nbsp;kr</strong>
        </div>

        <form class="product__form" [formGroup]="form" (ngSubmit)="onSubmit()" [class.submitted]="submitted" *ngIf="form">
          <div class="product__form__row" *ngIf="range">
            <div class="form-group product__price-range">
              <div class="input-group product__price-range__input">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="price">Prisnivå</label>
                </div>

                <input
                  class="form-control form-control-lg form-control-undisabled"
                  type="tel"
                  placeholder="Önskat pris"
                  formControlName="price"
                  id="price"
                  #priceInput
                  (keydown.enter)="$event.preventDefault()"
                  (keyup.enter)="$event.preventDefault()"
                />
                <div class="product__price-range__tooltip invalid-tooltip">
                  <ng-container *ngIf="range.step < 1">
                    Välj pris mellan {{ this.minPrice | price }} - {{ this.maxPrice | price }} kr.
                  </ng-container>
                  <ng-container *ngIf="range.step > 1">
                    Välj pris mellan {{ this.minPrice | price }} - {{ this.maxPrice | price }} kr med
                    {{ range.step * vatFactor | price }} kr spann.
                  </ng-container>
                </div>
              </div>

              <div class="product__price-range__buttons">
                <button
                  class="btn btn-dark"
                  type="button"
                  [disabled]="isMinPrice"
                  (click)="onClickDecreasePrice()"
                  (mousedown)="onMouseDownDecreasePrice()"
                  (mouseup)="stopAutoCount()"
                  (mouseout)="stopAutoCount()"
                >
                  <i class="icomoon icomoon-minus"></i>
                </button>
                <button
                  class="btn btn-dark"
                  type="button"
                  [disabled]="isMaxPrice"
                  (click)="onClickIncreasePrice()"
                  (mousedown)="onMouseDownIncreasePrice()"
                  (mouseup)="stopAutoCount()"
                  (mouseout)="stopAutoCount()"
                >
                  <i class="icomoon icomoon-plus"></i>
                </button>
              </div>
            </div>
          </div>

          <div
            class="product__form__row"
            *ngFor="let attribute of attributeControls; let i = index"
            [ngSwitch]="product.attributes[i].type"
          >
            <div class="form-group" *ngSwitchCase="'text'">
              <input
                type="text"
                class="form-control form-control-lg"
                id="field{{ i }}"
                [formControl]="attribute"
                placeholder="Ange {{ product.attributes[i].description | lowercase }} (frivilligt)"
              />
            </div>

            <div class="form-group custom-control custom-control-lg custom-checkbox" *ngSwitchCase="'option'">
              <input type="checkbox" class="custom-control-input" id="field{{ i }}" [formControl]="attribute" />
              <label class="custom-control-label" for="field{{ i }}"
                >Kryssa i för {{ product.attributes[i].description | lowercase }}</label
              >
            </div>

            <div class="form-group" *ngSwitchCase="'choice'">
              <select class="custom-select custom-select-lg" id="field{{ i }}" [formControl]="attribute">
                <option value="">Välj {{ product.attributes[i].description | lowercase }}</option>
                >
                <option *ngFor="let option of product.attributes[i].values" value="{{ option.id }}">{{ option.value }}</option>
              </select>
            </div>
          </div>

          <div class="product__form__row">
            <div class="form-group">
              <select class="custom-select custom-select-lg" formControlName="quantity">
                <option value="1">1 st</option>
                <option value="2">2 st</option>
                <option value="3">3 st</option>
                <option value="4">4 st</option>
                <option value="5">5 st</option>
                <option value="6">6 st</option>
                <option value="7">7 st</option>
                <option value="8">8 st</option>
                <option value="9">9 st</option>
                <option value="10">10 st</option>
              </select>
              <button type="submit" class="product__button btn btn-dark" [disabled]="isAdding">
                {{ !isAdding ? 'Lägg i varukorgen' : 'Lagd i varukorgen' }}
              </button>
            </div>
          </div>
        </form>

        <p class="product__description">{{ product.description }}</p>

        <ul class="product__extra" *ngIf="product.addons.length">
          <li *ngFor="let addon of product.addons">
            {{ addon.quantity }}&nbsp;st {{ addon.name }}
            <span class="product__extra__price">({{ addon.total * addon.quantity || 0 | price }}&nbsp;kr)</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="product__addons" *ngIf="addons.length">
      <h2 class="product__addons__title">Välj till</h2>
      <div class="product__addons__wrapper">
        <div class="product__addons__items owl-carousel" #sliderAddons>
          <div class="product__addons__item" *ngFor="let addon of addons">
            <div class="product__addons__item__image">
              <span class="product__addons__item__image__label" *ngIf="!addon.images.length">Bild saknas</span>
              <div
                class="product__addons__item__image__bg"
                [ngStyle]="{ 'background-image': 'url(' + getFirstImage(addon.images) + ')' }"
              ></div>
            </div>
            <h3 class="product__addons__item__title" [title]="addon.name">{{ addon.name }}</h3>
            <strong class="product__addons__item__price">{{ addon.total || 0 | price }}&nbsp;kr</strong>

            <div class="product__addons__buttons" *ngIf="!addon.quantity">
              <button class="product__addons__buttons__add btn btn-dark btn-sm" (click)="addAddon(addon, $event)">Lägg till</button>
            </div>

            <div class="product__addons__buttons" *ngIf="addon.quantity">
              <button class="product__addons__buttons__btn btn btn-outline-dark btn-sm" (click)="decreaseAddon(addon, $event)">
                <i class="icomoon icomoon-minus"></i>
              </button>
              <input type="tel" class="product__addons__buttons__quantity form-control" [value]="addon.quantity || 0" readonly disabled />
              <button
                class="product__addons__buttons__btn btn btn-outline-dark btn-sm"
                [disabled]="addon.quantity >= 10"
                (click)="increaseAddon(addon, $event)"
              >
                <i class="icomoon icomoon-plus"></i>
              </button>
            </div>
          </div>
        </div>
        <div #navAddons class="owl-secondary-nav" [hidden]="addons.length <= 2"></div>
      </div>
    </div>
  </div>
</section>
