import { Component, OnInit, ElementRef, ViewChild, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { NavService } from 'src/app/services/nav.service';
import Product from 'src/app/models/product.model';
import { CartService } from 'src/app/services/cart.service';
import { StoreService } from 'src/app/services/store.service';
import { IPriceRange } from 'src/app/models/price-range.interface';
import { takeUntil, delay, takeWhile } from 'rxjs/operators';
import { Subject, interval } from 'rxjs';
import { PricePipe } from 'src/app/pipes/price.pipe';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-product-page',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductPageComponent implements OnInit, OnDestroy {
  @ViewChild('slider', { static: true }) slider: ElementRef;
  @ViewChild('sliderAddons') sliderAddons: ElementRef;
  @ViewChild('navAddons') navAddons: ElementRef;
  @ViewChild('priceInput') priceInput: ElementRef;

  form: FormGroup;
  submitted: boolean;

  error: any;
  isLoading = true;
  isAdding: boolean;

  range: IPriceRange;
  product: Product = new Product();
  addons: Array<Product> = [];

  $owl: any;
  $owlAddons: any;

  ngUnsubscribe$: Subject<void> = new Subject<void>();
  stopAutoCount$: Subject<void> = new Subject<void>();

  get variablePrice(): number {
    return Math.round(parseFloat(this.form.get('price').value ? this.form.get('price').value.replace(/[^0-9.,]/g, '') : 0));
  }

  get minPrice(): number {
    return this.range.min * this.vatFactor;
  }

  get maxPrice(): number {
    return this.range.max * this.vatFactor;
  }

  get vatFactor(): number {
    return parseInt(this.product.tax, 10) / 100 + 1;
  }

  get isMinPrice(): boolean {
    return this.range && this.variablePrice - this.range.step <= this.minPrice;
  }

  get isMaxPrice(): boolean {
    return this.range && this.variablePrice + this.range.step >= this.maxPrice;
  }

  get rangeStepWithVat(): number {
    return this.range.step * this.vatFactor;
  }

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private navService: NavService,
    private cartService: CartService,
    private storeService: StoreService,
    private pricePipe: PricePipe,
    private cd: ChangeDetectorRef,
    private seoService: SeoService,
  ) { }

  ngOnInit() {
    this.loadProduct(this.route.snapshot.paramMap.get('id'));
    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
    if (this.$owl) {
      this.$owl.remove();
    }

    if (this.$owlAddons) {
      this.$owl.remove();
    }

    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  loadProduct(id: any) {
    this.isLoading = true;
    this.api.getProduct(id).subscribe(
      response => {
        this.product = { ...response.item, addons: [] };
        this.addons = response.item.addons;

        this.seoService.setMeta(this.product.name);

        this.initForm();
        this.cd.markForCheck();

        setTimeout(() => {
          this.initSlider();
          this.isLoading = false;
          this.cd.detectChanges();
          this.priceInput?.nativeElement?.select();
        }, 50);
      },
      () => {
        this.error = true;
        this.isLoading = false;
        this.cd.markForCheck();
      }
    );
  }

  initSlider() {
    // Main image slider
    this.$owl = $(this.slider.nativeElement);
    if (this.product.images.length > 1) {
      this.$owl.owlCarousel({
        loop: true,
        margin: 0,
        dots: true,
        nav: false,
        items: 1,
        startPosition: 0
      });
    }

    // Addons slider
    if (this.sliderAddons) {
      this.$owlAddons = $(this.sliderAddons.nativeElement);
      this.$owlAddons.owlCarousel({
        loop: false,
        margin: 30,
        dots: false,
        nav: true,
        mouseDrag: false,
        navText: [],
        navContainer: this.navAddons.nativeElement,
        responsive: {
          0: { items: 2 },
          768: { items: 3 },
          1024: { items: 4 },
          1200: { items: 6 },
          1400: { items: 7 }
        },
        startPosition: 0
      });
    }
  }

  initForm() {
    const attributes = this.product ? this.product.attributes : [];

    this.range = this.product && this.product.type === 'range' ? (this.product.price as IPriceRange) : null;

    const controlsConfig = {
      quantity: [1],
      attributes: this.fb.array(
        attributes.map(o => (o.type === 'choice' ? this.fb.control('', Validators.required) : this.fb.control('')))
      ),
      price: null
    };

    if (this.range) {
      controlsConfig.price = [null, [Validators.required, this.isPriceRangeValid(this.rangeStepWithVat)]];
    }

    this.form = this.fb.group(controlsConfig);

    if (this.range) {
      this.form
        .get('price')
        .valueChanges.pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(() => {
          this.product.total = this.variablePrice;
          this.product.vat = this.product.total - this.product.total / this.vatFactor;
        });
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.valid) {
      this.addToCart();
    }
  }

  onClickDecreasePrice() {
    const price = Math.ceil((this.variablePrice - this.rangeStepWithVat) / this.rangeStepWithVat) * this.rangeStepWithVat;
    this.form.get('price').setValue(`${this.pricePipe.transform(price)} kr`);
  }

  onClickIncreasePrice() {
    let price = Math.floor((this.variablePrice + this.rangeStepWithVat) / this.rangeStepWithVat) * this.rangeStepWithVat;

    // Start with min price
    if (price < this.minPrice) {
      price = this.minPrice;
    }

    this.form.get('price').setValue(`${this.pricePipe.transform(price)} kr`);
  }

  onMouseDownDecreasePrice() {
    this.stopAutoCount$.next();
    interval(50).pipe(
      delay(500),
      takeWhile(() => !this.isMinPrice),
      takeUntil(this.stopAutoCount$),
      takeUntil(this.ngUnsubscribe$)
    ).subscribe(() => {
      this.onClickDecreasePrice();
      this.cd.markForCheck();
    });
  }

  onMouseDownIncreasePrice() {
    this.stopAutoCount$.next();
    interval(50).pipe(
      delay(500),
      takeWhile(() => !this.isMaxPrice),
      takeUntil(this.stopAutoCount$),
      takeUntil(this.ngUnsubscribe$)
    ).subscribe(() => {
      this.onClickIncreasePrice();
      this.cd.markForCheck();
    });
  }

  stopAutoCount = () => this.stopAutoCount$.next();

  addToCart() {
    this.isAdding = true;
    this.cartService.add(this.product, this.form.value.quantity, this.form.value.attributes);

    // Close product page and open cart
    this.storeService.setCartState('open');
    this.close();
  }

  addAddon(addon: Product) {
    addon.quantity = 1;
    this.product.addons.push(addon);
  }

  increaseAddon(addon: Product) {
    const existing = this.product.addons.find(o => o.id === addon.id);
    if (existing) {
      existing.quantity += 1;
    }
  }

  decreaseAddon(addon: Product) {
    let index;
    const existing = this.product.addons.find((o, i) => {
      if (o.id === addon.id) {
        index = i;
        return true;
      }
    });

    if (existing) {
      existing.quantity -= 1;
      // Remove from array
      if (existing.quantity === 0) {
        this.product.addons.splice(index, 1);
      }
    }
  }

  close() {
    this.navService.back('/');
  }

  get attributeControls() {
    return (<FormArray>this.form.get('attributes')).controls;
  }

  getFirstImage = (images: any[]) => (images.length && images[0].large ? images[0].large : null);

  private isPriceRangeValid(delta: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const price = Math.round(parseFloat(control.value ? control.value.replace(/[^0-9.,]/g, '') : 0));
      return price < this.minPrice || price > this.maxPrice || price % delta !== 0 ? { 'invalidRange': { value: control.value } } : null;
    };
  }
}
