import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {
  transform(value: any): any {
    return new DecimalPipe('sv').transform(
      value,
      !Number.isInteger(value) ? '1.2' : '1.'
    );
  }
}
