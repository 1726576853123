import { Router, NavigationEnd, Scroll, NavigationExtras } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';

@Injectable()
export class NavService {
  private currentUrl: string;
  private previousUrl: string;

  constructor(private router: Router, private zone: NgZone) {
    router.events.subscribe(event => {
      // Store previous url
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
      // Restore scroll position
      else if (event instanceof Scroll) {
        setTimeout(() => (event.position ? window.scrollTo(0, event.position[1]) : false), 30);
      }
    });
  }

  goto(name: string, childname?: string, event?: any): void {
    if (event) {
      event.preventDefault();
    }

    const route = this.find(name);
    const childroute = childname ? this.find(name, childname) : null;
    if (route) {
      const url = childroute ? `${route.path}/${childroute.path}` : route.path;
      this.refresh(() => this.nagivate(url));
    } else {
      throw Error(`Failed to find requested route "${name}/${childname}"`);
    }
  }

  is(name: string, childname?: string): boolean {
    const route = this.find(name);
    const childroute = childname ? this.find(name, childname) : null;
    if (route) {
      const url = childroute ? `${route.path}/${childroute.path}` : route.path;
      return this.router.isActive(url, false);
    }
  }

  find = (name: string, childname?: string): any => {
    const route = this.router.config.find(r => r.data.name === name);
    return !childname ? route : route.children.find(childroute => childroute.data.name === childname);
  };

  url = (name: string, childname?: string): string => {
    const route = this.find(name, childname);
    return route.path;
  };

  back = (fallbackUrl: string) => {
    this.previousUrl && this.previousUrl !== '/test' ? window.history.back() : this.refresh(() => this.nagivate(fallbackUrl));
  };

  nagivate = (url: string, params?: {}, extras?: NavigationExtras): Promise<boolean> => {
    if (params !== undefined) {
      Object.keys(params).forEach(key => (url = url.replace(`:${key}`, params[key])));
    }
    return this.router.navigateByUrl(url, extras);
  };

  sanitize = (str: string) => {
    return str
      .toLowerCase()
      .replace('ö', 'o')
      .replace('ä', 'a')
      .replace('å', 'a')
      .replace(/[^a-z0-9_]/gi, '-')
      .replace(/^-|-$/gi, '');
  };

  private refresh = (fn: any = () => {}) => this.zone.run(fn);
}
