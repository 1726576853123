import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  Type
} from '@angular/core';
import { TextPageComponent } from 'src/app/pages/text/text.component';
import { NotFoundPageComponent } from 'src/app/pages/not-found/not-found.component';
import { ActivatedRoute } from '@angular/router';
import { ContentfulService } from 'src/app/services/contentful.service';
import { Entry } from 'contentful';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-contentful-resolver',
  templateUrl: './contentful-resolver.component.html',
  styleUrls: ['./contentful-resolver.component.scss']
})
export class ContentfulResolverComponent implements OnInit {
  @ViewChild('component', { read: ViewContainerRef, static: true })
  componentContainer: ViewContainerRef;

  isLoading: boolean;

  constructor(
    private route: ActivatedRoute,
    private componentFactoryResolver: ComponentFactoryResolver,
    private contentful: ContentfulService,
    private seoService: SeoService
  ) { }

  ngOnInit() {
    this.isLoading = true;

    this.route.params.subscribe(params => {
      window.scrollTo(0, 0);
      const slug = this.route.snapshot.paramMap.get('slug');

      this.contentful.getEntries<any>({ 'fields.slug': slug, include: 3, content_type: 'page' }).pipe().subscribe(
        response => {
          const entry = response.items[0];
          this.loadComponent(TextPageComponent, entry);
          this.seoService.setMeta(entry.fields.metaTitle ?? entry.fields.title, entry.fields.metaDescription);
        }, () => this.loadComponent(NotFoundPageComponent)
      );
    });
  }

  private loadComponent(component: Type<unknown>, data?: Entry<any>) {
    this.isLoading = false;
    const factory = this.componentFactoryResolver.resolveComponentFactory(
      component
    );
    this.componentContainer.clear();

    const componentRef = this.componentContainer.createComponent(factory);
    const instance = <any>componentRef.instance;
    instance.data = data;
  }
}
