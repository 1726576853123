import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Sanitizer } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { StoreService } from 'src/app/services/store.service';
import { NavService } from 'src/app/services/nav.service';
import { ApiService } from 'src/app/services/api.service';

import { finalize, startWith, switchMap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import Product from 'src/app/models/product.model';
import Order from 'src/app/models/order.model';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import SwishQr from 'src/app/models/swish-qr.model';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-swish-page',
  templateUrl: './swish.component.html',
  styleUrls: ['./swish.component.scss']
})
export class SwishPageComponent implements OnInit, OnDestroy {
  @ViewChild('scrollcontent', { static: true }) scrollcontent: ElementRef;

  products: Product[];

  isLoading: boolean;
  error: any;
  pollSubscription: Subscription;

  qr: SwishQr;
  hash: string;

  scrollbars: any;

  get swishUrl(): string {
    if (!this.qr) {
      return;
    }

    const payload = {
      version: 1,
      payee: { value: this.qr.receiver },
      amount: { value: this.qr.amount },
      message: { value: this.qr.message }
    };

    const callbackUrl = window.location.href;

    return `swish://payment?data=${encodeURIComponent(
      JSON.stringify(payload)
    )}&callbackurl=${callbackUrl}&callbackresultparameter=swishCallback`;
  }

  get swishSafeUrl(): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(this.swishUrl);
  }

  get isSwishCallback(): boolean {
    return window.location.href.includes('swishCallback');
  }

  constructor(
    private cartService: CartService,
    private storeService: StoreService,
    private navService: NavService,
    private apiService: ApiService,
    private activeRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private seoService: SeoService
  ) { }

  ngOnInit() {
    this.storeService.setCartState('closed');
    this.hash = this.activeRoute.snapshot.paramMap.get('hash');

    this.loadOrder();
    this.loadSwishQrCode();
    window.scrollTo(0, 0);

    this.seoService.setMeta('Betala via Swish');
  }

  loadOrder() {
    this.isLoading = true;
    this.apiService
      .getOrder(this.hash)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        order => this.initCart(order),
        response => (this.error = `${response.status} ${response.statusText}, ${response.message}`)
      );
  }

  loadSwishQrCode() {
    this.isLoading = true;
    this.apiService
      .initSwish(this.hash)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        response => {
          this.qr = response;

          if (!this.qr.payed && !this.isSwishCallback) {
            window.location.href = this.swishUrl;
          }

          this.pollStatus();
        },
        response => (this.error = `${response.status} ${response.statusText}, ${response.message}`)
      );
  }

  pollStatus() {
    this.pollSubscription = interval(3000)
      .pipe(
        startWith(500),
        switchMap(() => this.apiService.waitPayment(this.hash))
      )
      .subscribe(
        isPayed => {
          if (isPayed) {
            const url = this.navService.url('thankyou');
            this.navService.nagivate(url, { hash: this.hash }, { replaceUrl: true });
          }
        },
        response => (this.error = `${response.status} ${response.statusText}, ${response.message}`)
      );
  }

  initCart(order: Order) {
    this.scrollbars = OverlayScrollbars(this.scrollcontent.nativeElement, {
      className: 'os-theme-dark',
      sizeAutoCapable: true,
      paddingAbsolute: true,
      scrollbars: {
        autoHide: 'leave',
        autoHideDelay: 100
      }
    });

    this.cartService.setProducts(order.items.map(item => Product.createFromOrderItem(item)));
    this.cartService.setShipping(order.shipping);

    this.products = this.cartService.items();
  }

  ngOnDestroy() {
    if (this.pollSubscription) {
      this.pollSubscription.unsubscribe();
    }

    if (this.scrollbars) {
      this.scrollbars.destroy();
    }
  }

  close() {
    const confirmed = window.confirm('Är du säker på att du vill avbryta din beställning?');
    if (confirmed) {
      this.cartService.clear();
      this.navService.goto('start');
    }
  }

  getTotal = (): number => this.cartService.total();

  getTotalVat = () => this.cartService.vat();

  getShipping = () => (this.cartService.shipping() ? this.cartService.shipping().charge.total : 0);

  getShippingTitle = () => (this.cartService.shipping() ? this.cartService.shipping().name : 'Frakt');
}
