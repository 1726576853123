import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable, BehaviorSubject } from "rxjs";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { NavService } from "./nav.service";
import FilterCategory from "../models/filter-category.model";
import { SessionCacheService } from "./cache/session-cache.service";
import { filter, map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class FilterService {
  defaultCategory: string;

  get categories(): Array<FilterCategory> {
    return this.categories$.value || [];
  }

  get categoryChanges(): Observable<Array<FilterCategory>> {
    return this.categories$.asObservable().pipe(filter(categories => categories !== null));
  }

  get categoryCheckedChanges(): Observable<Array<FilterCategory>> {
    return this.categoryChanges.pipe(map(categories => categories.filter(category => category.checked)));
  }

  private categories$ = new BehaviorSubject<Array<FilterCategory>>(null);

  constructor(
    private api: ApiService,
    private sessionCache: SessionCacheService,
    private route: ActivatedRoute,
    private router: Router,
    private navService: NavService
  ) {
    // Listen on URL change for filter
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      if (this.categories$.value !== null) {
        this.trigger(this.categories$.value);
      }
    });

    // Load categories
    this.loadData();
  }

  title = (asArray: boolean = false): any => {
    const categories = this.categories
      .map(o => (o.checked ? o.label : null))
      .filter(v => v !== null);
    return asArray ? categories : categories.join(", ");
  };

  private loadData() {
    const filter = { group: 1 };
    const cacheKey = this.sessionCache.hash({ key: "categories", filter });
    const cache = this.sessionCache.get(cacheKey);

    if (cache) {
      this.trigger(cache);
    } else {
      this.api.getCategories(filter).pipe(
        map(response => response.items.map(item => ({
          slug: this.navService.sanitize(item.name),
          label: item.name,
          value: item.id,
          checked: false
        })))
      ).subscribe(categories => {
        if (categories.length) {
          this.sessionCache.set(cacheKey, categories, null, 60 * 10);
        }
        this.trigger(categories);
      }, () => this.trigger([]));
    }
  }

  private trigger(categories: Array<FilterCategory>) {
    const route = this.route.firstChild;
    const { queryParams, params, data } = route.firstChild?.snapshot || route.snapshot;
    let filters = queryParams.filter?.split(",") || params.filter?.split(",") || [];

    // Force default category on startpage
    if (this.router.isActive('/', true)) {
      filters.push(this.navService.sanitize('Säsongsbukett'));
    }

    categories.forEach(category => category.checked = filters.includes(category.slug));
    this.categories$.next(categories);
  }

  public setChecked(checked: FilterCategory[]) {
    const { queryParams } = this.route.firstChild.snapshot;
    const url = window.location.pathname.replace(/(^\/.*?)\/.*?$/g, "$1");

    // Mark checked categories
    this.categories.forEach(
      o => (o.checked = !!checked.find(c => c.slug === o.slug))
    );

    const filter = [...checked]
      .map(o => o.slug)
      .reduce((x, y) => (x.includes(y) ? x : [...x, y]), []);

    if (!queryParams.filter && !filter.length) {
      this.router.navigate([url]);
      this.categories$.next(this.categories);
    } else if (filter.length === 1) {
      this.router.navigate([url, filter[0]]);
    } else {
      this.router.navigate([url], {
        relativeTo: this.route,
        queryParams: {
          filter: filter.length ? filter.join(",") : null
        }
      });
    }
  }
}
