import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { Entry } from "contentful";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { SafeHtml, DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-text",
  templateUrl: "./text.component.html",
  styleUrls: ["./text.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class TextPageComponent implements OnInit {
  @Input() data: Entry<any>;

  body: SafeHtml;
  body2: SafeHtml;
  hero: Entry<any>;
  reviews: Entry<any>[] = [];

  constructor(private sanitizer: DomSanitizer) {}

  get showSidebar(): boolean {
    return this.hero && (this.hero.fields.slogan || this.heroImages.length);
  }

  get heroImages(): { url: string; title: string }[] {
    return this.hero && this.hero.fields.images
      ? this.hero.fields.images.map(o => ({
          title: o.fields.title,
          url: o.fields.file.url
        }))
      : [];
  }

  ngOnInit() {
    // Split body into two parts, if body contains any embedded entry
    const first = { content: [], ...this.data.fields.body };
    const second = { ...this.data.fields.body, content: [] };

    for (let i = 0; i < first.content.length; i++) {
      const content = first.content[i];
      if (content.nodeType === "embedded-entry-block") {
        second.content = first.content.splice(i, first.content.length);
        break;
      }
    }

    this.hero = this.data.fields.hero;

    this.body = this.sanitizer.bypassSecurityTrustHtml(
      this.documentToHtmlString(first)
    );
    this.body2 = this.sanitizer.bypassSecurityTrustHtml(
      this.documentToHtmlString(second)
    );
  }

  private documentToHtmlString(body): string {
    const options = {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, next) =>
          `<p>${next(node.content).replace(/\n/g, "<br />")}</p>`,
        [BLOCKS.EMBEDDED_ENTRY]: node => this.renderEmbedded(node.data.target)
      }
    };

    return documentToHtmlString(body, options);
  }

  private renderEmbedded(entry: Entry<any>): string {
    if (!entry.sys.contentType) {
      return "";
    }

    switch (entry.sys.contentType.sys.id) {
      case "review":
        const { align, image } = entry.fields;
        return `
          <div class="text__review text__review--${
            align ? align.toLowerCase() : "right"
          }">
            <div class="text__review__main">
              ${this.documentToHtmlString(entry.fields.body)}
            </div>
            ${
              image
                ? `<div class="text__review__sidebar">
                <div class="text__review__image" style="background-image:url('${image.fields.file.url}')"></div>
              </div>
              `
                : ""
            }
          </div>
        `;
    }
    return "";
  }
}
