import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { ContentfulService } from 'src/app/services/contentful.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {
  mainItems: [] = [];
  footerItems: [] = [];
  footerTitle: string;

  constructor(
    private contentful: ContentfulService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    this.contentful
      .getEntries<any>({
        'fields.slug[in]': 'footer,contact',
        content_type: 'navigation'
      })
      .subscribe(response => {
        response.items.forEach(({ fields }) => {
          if (fields.slug === 'footer') {
            this.mainItems = fields.items;
          } else if (fields.slug === 'contact') {
            this.footerTitle = fields.title;
            this.footerItems = fields.items;
          }
        });
        this.cd.markForCheck();
      });
  }
}
