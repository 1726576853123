import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, pipe } from 'rxjs';
import { environment } from '../../environments/environment';

import { APP_BASE_HREF } from '@angular/common';
import Order from '../models/order.model';
import { map } from 'rxjs/operators';
import OrderItem from '../models/order-item.model';
import Store from '../models/store.model';
import SwishQr from '../models/swish-qr.model';

@Injectable()
export class ApiService {
  constructor(@Inject(APP_BASE_HREF) private baseHref: string, private http: HttpClient) {
    this.baseHref = environment.api;
  }

  public getProducts(type: string = 'all', filter: {} = {}, offset: number = 0, limit: number = 25): Observable<any> {
    const url = `${this.baseHref}/products/${type}`;
    const params = this.params({
      offset,
      limit,
      filter: filter || {}
    });
    return this.http.get<any>(url, { params });
  }

  public getProduct(id: string): Observable<any> {
    const url = `${this.baseHref}/product/${id}`;
    return this.http.get<any>(url);
  }

  public getCategories(
    filter: {} = {},
    orderby: string = 'date',
    order: string = 'desc',
    offset: number = 0,
    limit: number = 100
  ): Observable<any> {
    const url = `${this.baseHref}/categories`;
    const params = this.params({
      offset,
      limit,
      filter: filter || {},
      orderby,
      order
    });
    return this.http.get<any>(url, { params });
  }

  public createOrder(order: Order): Observable<{ order: Order; url: string }> {
    const url = `${this.baseHref}/orders`;
    return this.http.post<any>(url, order);
  }

  public getOrder(hash: string): Observable<Order> {
    const url = `${this.baseHref}/orders/${hash}`;
    return this.http.get<any>(url).pipe(
      map(response => ({
        ...response.item,
        shipping: response.item.supplier.shipping
      }))
    );
  }

  public commitOrder(hash: string): Observable<boolean> {
    const url = `${this.baseHref}/orders/${hash}/commit`;
    return this.http.get<any>(url).pipe(map(response => response.item));
  }

  public getOrderItems(
    hash: string,
    filter: {} = {},
    orderby: string = 'date',
    order: string = 'desc',
    offset: number = 0,
    limit: number = 0
  ): Observable<OrderItem[]> {
    const url = `${this.baseHref}/orders/${hash}/items`;
    const params = this.params({
      offset,
      limit,
      filter: filter || {},
      orderby,
      order
    });
    return this.http
      .get<any>(url, { params })
      .pipe(map(response => response.items));
  }

  public log(message: string, url: string, level: string = 'error'): Observable<boolean> {
    return this.http.post<any>(`${this.baseHref}/logs`, { message, url, level });
  }

  public initSwish(hash: string): Observable<SwishQr> {
    const url = `${this.baseHref}/payment/swish/qr/${hash}`;
    return this.http.get<any>(url).pipe(map(response => response.item));
  }

  public simulateSwish(hash: string): Observable<any> {
    const url = `${this.baseHref}/payment/swish/qr/${hash}/simulate`;
    return this.http.get<any>(url);
  }

  public waitPayment(hash: string): Observable<boolean> {
    const url = `${this.baseHref}/payment/${hash}/wait`;
    return this.http.get<any>(url).pipe(map(response => ['payed', 'reserved'].includes(response.item)));
  }

  public searchZip(query: string): Observable<any> {
    if (query === '') {
      return of([]);
    }

    const url = `${this.baseHref}/zip/search/${query}`;
    return this.http.get<any>(url).pipe(map(response => response.items));
  }

  public getStore(
    zip: number,
    date: string,
    filter: {} = {},
    orderby: string = 'id',
    order: string = 'desc',
    offset: number = 0,
    limit: number = 0
  ): Observable<Store[]> {
    const url = `${this.baseHref}/stores/${zip}/${date}`;
    const params = this.params({
      offset,
      limit,
      filter: filter || {},
      orderby,
      order
    });
    return this.http
      .get<any>(url, { params })
      .pipe(map(response => response.items));
  }

  public searchHitta(query: string): Observable<any> {
    if (query === '') {
      return of([]);
    }

    query = query.trim().replace(/^\+/, '00').replace(/\+/, ' ');

    const url = `${this.baseHref}/hitta/search/${query}`;
    return this.http.get<any>(url).pipe(map(response => response.items));
  }

  private params(data: {}): {} {
    Object.keys(data).forEach(name => {
      // 1st level
      if (typeof data[name] !== 'object') {
        data[name] = data[name].toString();

        // 2nd level object
      } else {
        Object.keys(data[name]).forEach(subname => {
          data[`${name}[${subname}]`] = data[name][subname] ? data[name][subname].toString() : '';
        });
        delete data[name];
      }
    });
    return data;
  }

  public optinNewsletter(email: string): Observable<boolean> {
    const url = `${this.baseHref}/newsletter`;
    return this.http.post<boolean>(url, { email });
  }
}
