<section class="thanks">
  <div class="thanks__container container">
    <button type="button" class="btn-close hamburger hamburger--slider is-active" (click)="close()">
      <span class="hamburger-box"> <span class="hamburger-inner"></span> </span>
    </button>

    <div class="thanks__loading" *ngIf="isLoading">
      <div class="loader loader--no-delay"></div>
      <p class="loader__text" *ngIf="isLoadingPayment">Inväntar betalning</p>
    </div>

    <div class="text-center" [hidden]="isLoading">
      <app-logo class="thanks__logo" [isDark]="true" xsize="small"></app-logo>
    </div>

    <div class="row">
      <div class="col-md-8" *ngIf="!isLoading && !error">
        <h2 class="thanks__title">Tack för din beställning</h2>
        <p class="thanks__text">
          Orderbekräftelsen kommer skickas ut per mejl inom kort. Vid frågor kontakta butiken nedan.
        </p>

        <div class="row">
          <div class="col-sm-6 mb-3">
            <h4 class="mb-3">Ordernummer</h4>
            {{order.infrasec_order_id || 'T'+order.id}}
          </div>
          <div class="col-sm-6 mb-3">
            <h4 class="mb-3">{{isFuneral ? 'Leveransdag och tid' : 'Leveransdag'}}</h4>
            {{order.delivery_at | date: 'EEEE, dd MMMM, yyyy' | titlecase}}
            <span *ngIf="isFuneral">, kl. {{order.delivery_time}}</span>
          </div>
        </div>
        <hr class="mb-4" />

        <div class="row">
          <div class="col-sm-6 mb-3">
            <h4 class="mb-3">{{isFuneral ? 'Mottagare / Bår' : 'Mottagare'}}</h4>
            {{order.receiver_firstname}} {{order.receiver_lastname}}<br>
            <span *ngIf="isFuneral">{{order.receiver_church}}<br></span>
            {{order.receiver_address}}<br>
            {{order.receiver_zip}} {{order.receiver_city}}
            <span *ngIf="!isFuneral"><br>Telefon: {{order.receiver_phone}}</span>
          </div>
          <div class="col-sm-6 mb-3">
            <h4 class="mb-3">Avsändare</h4>
            {{order.sender_name}}<br>
            {{order.sender_phone}}<br>
            {{order.sender_email}}
          </div>
        </div>
        <hr class="mb-4" />

        <div class="row">
          <div class="col-12 col-sm-6 mb-3" *ngIf="order.meta.message">
            <h4 class="mb-3">Hälsningstext</h4>
            {{order.meta.message.value}}
            <hr class="mb-3 mt-4 d-sm-none" *ngIf="order.meta.other" />
          </div>
          <div class="col-12 col-sm-6 mb-3" *ngIf="order.meta.other">
            <h4 class="mb-3">Övrigt</h4>
            {{order.meta.other.value}}
          </div>
        </div>
        <hr class="mb-4" *ngIf="order.meta.message || order.meta.other" />

        <div class="row">
          <div class="col-sm-6 mb-3">
            <h4 class="mb-3">Butik</h4>
            {{order.supplier.store.name}}<br>
            {{order.supplier.store.phone}}
            <hr class="mb-3 mt-4 d-sm-none" />
          </div>
          <div class="col-sm-6 mb-3">
            <h4 class="mb-3">Betalssätt</h4>
            <a href="https://www.getswish.se" title="Läs mer om Swish" target="_blank"
              class="thanks__payment thanks__payment--swish" *ngIf="order.payment === 'swish'">Swish</a>
            <a href="https://www.swedbankpay.se" title="Läs mer om Swedbank Pay" target="_blank"
              class="thanks__payment thanks__payment--card" *ngIf="order.payment === 'card'">Kortbetalning</a>
          </div>
        </div>
        <hr class="mb-4 d-sm-none" />
      </div>

      <div class="col-md-8" *ngIf="error">
        <div class="alert alert-secondary" role="alert">
          <h4 class="alert-heading">Ajdå!</h4>
          <p>Vi kunde tyvärr inte hämta din beställning.</p>
          <hr>
          <p class="mb-0">Felkod: {{error}}</p>
        </div>
      </div>

      <div class="col-md-4 mb-4" [hidden]="isLoading || error">
        <h4 class="d-flex justify-content-between align-items-center mb-3 thanks__cart__title">
          Varukorg
        </h4>
        <div #scrollcontent class="thanks__cart">
          <p class="thanks__cart__empty" *ngIf="!cart.products || !cart.products.length">Din varukorg är tom</p>
          <app-cart-list [products]="cart.products" [readonly]="true"></app-cart-list>
        </div>
        <div class="thanks__cart__footer" *ngIf="cart.products && cart.products.length">
          <table class="thanks__cart__price">
            <tr>
              <td>{{getShippingTitle()}}</td>
              <td>{{getShipping() | price}}&nbsp;kr</td>
            </tr>
            <tr class="thanks__cart__price__total">
              <td>Totalt inkl moms</td>
              <td>{{getTotal(true) | price}}&nbsp;kr</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>
