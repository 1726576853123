import md5 from './md5';
import FallbackStorage from './fallback';

export default class Cache {
  private storage;

  constructor(type: string = 'local') {
    // Set storage method
    if (type === 'memory') {
      this.storage = new FallbackStorage();
    } else {
      try {
        // Test if native storage is working
        this.storage = type === 'local' ? window.localStorage : window.sessionStorage;
        this.storage.setItem('CacheService', 'Testing local storage');
        this.storage.removeItem('CacheService');
      } catch(ex) {
        this.storage = new FallbackStorage();
      }
    }
  }

  set = (key, data, group = null, expire = 0) => {
    try {
      const cacheKey = (group ? `${group}-${key}` : key).toLowerCase();
      this.storage.setItem(cacheKey, JSON.stringify({
        data,
        expire: expire ? new Date().getTime() + 1000 * expire : 0, // Expire timestamp
        ttl: expire % 86400 === 0 ? `${expire/86400} days` : expire % 60 === 0 ? `${expire/60} minutes` : expire % 3600 === 0 ? `${expire/3600} hours` : `${expire} seconds` // Readable "time to live" description
      }));
    }catch (ex) { /* Silent */ }
  };

  get = (key, group = null) => {
    try {
      const cacheKey = (group ? `${group}-${key}` : key).toLowerCase();
      const value = this.storage.getItem(cacheKey);
      if (value !== null) {
        const cache = JSON.parse(value);
        const expire = new Date();
        expire.setTime(cache.expire);
        if (cache.expire > 0 && expire < new Date()) {
          this.delete(key, group);
        } else {
          return cache.data;
        }
      }
    } catch (ex) { /* Silent */ }

    return null;
  };

  delete = (key, group = null) => {
    try {
      if (key !== null) {
        const cacheKey = (group ? `${group}-${key}` : key).toLowerCase();
        this.storage.removeItem(cacheKey);
      } else if (key === null && group !== null) {
        const keysToRemove = [];
        for (let i = 0; i < this.storage.length; i++) {
          const cacheKey = this.storage.key(i);
          if (cacheKey.indexOf(`${group.toLowerCase()}-`) === 0) {
            keysToRemove.push(cacheKey);
          }
        }
        keysToRemove.forEach(cacheKey => this.storage.removeItem(cacheKey));
      }
    }catch (ex) { /* Silent */ }
  };

  clear = () => this.storage.clear();

  // Generate a hash from object to use as cache key e.g. query params
  hash = any => md5(JSON.stringify(any));
}
