import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  private defaultTitle: string;
  private defaultDescription: string;

  constructor(
    private titleService: Title,
    private metaService: Meta
  ) {
    this.defaultTitle = this.titleService.getTitle();
    this.defaultDescription = this.metaService.getTag('name=description').getAttribute('content');
  }

  setMeta(title: string, description?: string) {
    this.titleService.setTitle(title || this.defaultTitle);
    this.metaService.updateTag({ name: 'description', content: description || this.defaultDescription });
  }
}
