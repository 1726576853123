import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  AfterViewInit
} from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { StoreService } from 'src/app/services/store.service';
import { NavService } from 'src/app/services/nav.service';
import Product from 'src/app/models/product.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  // Manually repaint changes due to scrollbars constant repaint
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('scrollcontent', { static: true }) scrollcontent: ElementRef;

  products: Product[];

  isOpen: boolean;
  noAnimation: boolean;

  scrollbars: any;

  subscriptions: Subscription[] = [];

  constructor(
    private cartService: CartService,
    private storeService: StoreService,
    private navService: NavService,
    private cdr: ChangeDetectorRef
  ) {
    this.products = this.cartService.items();

    this.subscriptions.push(
      this.cartService.observe().subscribe(cart => {
        this.products = cart.products;
        this.cdr.markForCheck();
      })
    );

    this.subscriptions.push(
      this.storeService.observeCartState().subscribe(state => {
        this.isOpen = state === 'open';
        this.cdr.markForCheck();
      })
    );
  }

  ngOnInit() {
    this.isOpen = this.storeService.getCartState() === 'open';
  }

  ngOnDestroy() {
    this.scrollbars.destroy();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngAfterViewInit() {
    this.initScroll();
  }

  initScroll() {
    this.scrollbars = OverlayScrollbars(this.scrollcontent.nativeElement, {
      className: 'os-theme-dark',
      sizeAutoCapable: true,
      paddingAbsolute: true,
      scrollbars: {
        autoHide: 'leave',
        autoHideDelay: 100
      }
    });
  }

  open = () => this.storeService.setCartState('open');
  close = () => this.storeService.setCartState('closed');

  checkout() {
    this.navService.goto('checkout');
  }

  getTotal = () => this.cartService.total(false);
}
