<footer class="footer">
  <div class="footer__container container">
    <app-logo class="footer__logo" size="small"></app-logo>
    <div class="footer__menu">
      <nav class="footer__menu__col">
        {{ footerTitle }}<br />
        <a
          *ngFor="let item of footerItems"
          [href]="item.fields?.url || '/' + item.fields?.slug"
          [target]="item.fields?.blank ? '_blank' : '_self'"
          >{{ item.fields?.title }}</a
        >
      </nav>
      <nav class="footer__menu__col">
        <a
          *ngFor="let item of mainItems"
          [href]="item.fields?.url || '/' + item.fields?.slug"
          [target]="item.fields?.blank ? '_blank' : '_self'"
          >{{ item.fields?.title }}</a
        >
      </nav>
      <nav class="footer__menu__col footer__menu__col--align-right">
        <span class="footer__menu__payment" title="Våra betalsätt"></span>
      </nav>
    </div>
    <div class="footer__social">
      <a href="https://www.facebook.com/florify" target="_blank" class=""
        ><i class="icomoon icomoon-facebook"><span class="path1"></span><span class="path2"></span></i
      ></a>
      <a href="https://www.instagram.com/florifyblomster" target="_blank" class=""
        ><i class="icomoon icomoon-instagram"><span class="path1"></span><span class="path2"></span></i
      ></a>
    </div>
  </div>
</footer>
