<a
  [routerLink]="url"
  class="logo logo--{{ size }}"
  [ngClass]="{ 'logo--white': !isDark, 'logo--dark': isDark, 'logo--hidden': !visible, 'logo--nolink': !url }"
>
  <i class="icomoon icomoon-logo">
    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span
    ><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span
    ><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span
    ><span class="path15"></span>
  </i>
</a>
