/// <reference path="../typings.d.ts" />

import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_BASE_HREF, PlatformLocation, registerLocaleData, DatePipe } from '@angular/common';
import { NgbDatepickerModule, NgbModalModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

import swedish from '@angular/common/locales/sv';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NavService } from './services/nav.service';
import { FilterService } from './services/filter.service';
import { MemoryCacheService } from './services/cache/memory-cache.service';
import { SessionCacheService } from './services/cache/session-cache.service';
import { PermanentCacheService } from './services/cache/permanent-cache.service';
import { ApiService } from './services/api.service';
import { StoreService } from './services/store.service';
import { HeaderComponent } from './components/header/header.component';
import { HeroComponent } from './components/hero/hero.component';
import { LogoComponent } from './components/logo/logo.component';
import { FooterComponent } from './components/footer/footer.component';
import { FilterComponent } from './components/filter/filter.component';
import { ProductsComponent } from './components/products/products.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { MenuComponent } from './components/menu/menu.component';
import { CartComponent } from './components/cart/cart.component';
import { CartService } from './services/cart.service';
import { CartListComponent } from './components/cart/list/cart-list.component';
import { PricePipe } from './pipes/price.pipe';

import { CheckoutPageComponent } from './pages/checkout/checkout.component';
import { SwishPageComponent } from './pages/swish/swish.component';
import { ThankYouPageComponent } from './pages/thank-you/thank-you.component';
import { ProductPageComponent } from './pages/product/product.component';
import { NotFoundPageComponent } from './pages/not-found/not-found.component';
import { StartPageComponent } from './pages/startpage/startpage.component';
import { ProductsPageComponent } from './pages/products/products.component';
import { ZipPipe } from './pipes/zip.pipe';
import { TextPageComponent } from './pages/text/text.component';
import { HittaComponent } from './components/hitta.se/hitta.se.component';
import { ContentfulResolverComponent } from './components/contentful-resolver/contentful-resolver.component';
import { TestPageComponent } from './pages/test/test.component';
import { GlobalErrorHandler } from './global-error.handler';
import { ErrorInterceptor } from './error.interceptor';
import { CategoriesComponent } from './components/categories/categories.component';

export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}

registerLocaleData(swedish);

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientJsonpModule,
    HttpClientModule,
    NgbDatepickerModule,
    NgbModalModule,
    NgbTypeaheadModule
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    HeroComponent,
    LogoComponent,
    FooterComponent,
    FilterComponent,
    ProductsComponent,
    NewsletterComponent,
    MenuComponent,
    CartComponent,
    CartListComponent,
    PricePipe,
    StartPageComponent,
    ProductPageComponent,
    NotFoundPageComponent,
    SwishPageComponent,
    CheckoutPageComponent,
    ThankYouPageComponent,
    ProductsPageComponent,
    TextPageComponent,
    ZipPipe,
    HittaComponent,
    ContentfulResolverComponent,
    TestPageComponent,
    CategoriesComponent
  ],
  providers: [
    MemoryCacheService,
    SessionCacheService,
    PermanentCacheService,
    NavService,
    FilterService,
    StoreService,
    ApiService,
    CartService,
    DatePipe,
    ZipPipe,
    PricePipe,
    Title,
    Meta,
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseHref,
      deps: [PlatformLocation]
    },
    { provide: LOCALE_ID, useValue: 'sv' },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // Init NavService, so we can store previous URL for back functionality
  constructor(private navService: NavService) { }
}
