<section class="test">
  <div class="test__container container">
    <button type="button" class="test__close btn-close hamburger hamburger--slider is-active" (click)="close()">
      <span class="hamburger-box"> <span class="hamburger-inner"></span> </span>
    </button>

    <div class="row">
      <div class="col-md-12">
        <h4 class="test__title d-flex justify-content-between align-items-center mb-3">
          Test
        </h4>
        <form class="test__form" [formGroup]="form" (ngSubmit)="onSubmit()" [ngClass]="{'submitted': submitted}"
          *ngIf="!isTest">
          <div class="input-group input-group-lg">
            <input type="password" class="form-control" formControlName="password" placeholder="Lösenord">
            <div class="input-group-append">
              <button class="btn btn-primary" type="submit">{{ isLoading ? 'Skickar' : 'Logga in' }}</button>
            </div>
          </div>
        </form>
        <button class="btn btn-outline-dark" *ngIf="isTest" (click)="onClickLogout()">Avbryt</button>&nbsp;
        <button class="btn btn-primary" *ngIf="isTest" (click)="onClickContinue()">Fortsätt</button>
      </div>
    </div>
  </div>
</section>
