import OrderItem from './order-item.model';
import OrderMeta from './order-meta.model';
import Store from './store.model';
import Shipping from './shipping.model';

export default class Order {
  id?: string;
  infrasec_order_id?: string;
  hash?: string;
  mode?: string;
  type?: string;
  payment: string;
  amount: number;

  meta: {
    message: OrderMeta;
    other?: OrderMeta;
  } = {
    message: null
  };

  payed_updated_at?: string;
  reserved_updated_at?: string;
  refunded_updated_at?: string;

  delivery_at?: string;
  created_at?: string;
  updated_at?: string;
  prepared_at?: string;
  commited_at?: string;

  delivery_time?: string;
  receiver_church?: string;

  sender_name: string;
  sender_email: string;
  sender_phone: string;

  receiver_firstname: string;
  receiver_lastname: string;
  receiver_email: string;
  receiver_phone: string;
  receiver_address: string;
  receiver_zip: string;
  receiver_city: string;

  items?: OrderItem[];
  store: Store;
  shipping: Shipping;

  public static crateFromFormValues(values: {}): Order {
    const order = new Order();
    Object.keys(values).forEach(key => {
      if (key === 'meta') {
        Object.keys(values[key])
          .filter(subkey => values[key][subkey])
          .forEach(subkey => {
            order[key][subkey] = {
              infrasec_id: null,
              name: subkey,
              value: values[key][subkey]
            };
          });
      } else {
        order[key] = values[key];
      }
    });
    return order;
  }
}
