import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { MemoryCacheService } from 'src/app/services/cache/memory-cache.service';
import Product from 'src/app/models/product.model';
import { FilterService } from 'src/app/services/filter.service';
import { Subject, Subscription } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { SeoService } from 'src/app/services/seo.service';
import { ContentfulService } from 'src/app/services/contentful.service';

import { marked } from 'marked';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductsComponent implements OnInit, OnDestroy {
  products = [];

  error: any;
  isLoading: boolean;

  title: string;
  text: string;
  footerText: string;

  offset = 0;
  limit = 200;

  subscriptions: Subscription[] = [];

  private ngUnsubscribe$ = new Subject<void>();

  get isStartpage(): boolean {
    return this.router.isActive('/', true);
  }

  get deeplinkSlug(): string {
    return this.activatedRoute.snapshot.params.filter;
  }

  constructor(
    private api: ApiService,
    private memoryCache: MemoryCacheService,
    private cdr: ChangeDetectorRef,
    private filterService: FilterService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private contentful: ContentfulService,
    private seoService: SeoService,
  ) { }

  ngOnInit() {
    this.filterService.categoryCheckedChanges.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe((categories) => {
      this.loadProducts(categories.map(category => category.slug));
      this.setContentAndMeta();
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  setContentAndMeta() {
    const titles = this.filterService.title(true);
    const fallbackTitle = titles.length === 1 ? titles[0] : null;

    if (this.deeplinkSlug) {
      this.contentful.getEntries<any>({
        content_type: 'category',
        'fields.slug': this.deeplinkSlug
      }).pipe(
        finalize(() => this.cdr.markForCheck()),
        takeUntil(this.ngUnsubscribe$)
      ).subscribe(response => {
        if (response.items.length) {
          const item = response.items[0];
          const metaTitle = item?.fields?.metaTitle || this.filterService.title();
          this.seoService.setMeta(metaTitle, item?.fields?.metaDescription);

          this.title = item.fields.title ? item.fields.title : fallbackTitle;
          this.text = item.fields.text?.replace("\n", '<br /><br />');

          if (item.fields.footerText) {
            this.footerText = marked(item.fields.footerText);
          }
        }
      });
    } else {
      this.title = fallbackTitle;
      this.text = null;
      this.footerText = null;
      this.seoService.setMeta(this.filterService.title(), null);
    }
  }

  loadProducts(slug: Array<string>) {
    this.error = false;

    // Get filter from URL query
    const filter = {
      categories: slug
    };

    const cacheKey = this.memoryCache.hash({
      key: 'products',
      offset: this.offset,
      limit: this.limit,
      filter
    });
    const cache = this.memoryCache.get(cacheKey);

    if (cache) {
      this.products = cache;
    } else {
      this.isLoading = true;
      this.api.getProducts('flowers', filter, this.offset, this.limit).subscribe(
        response => {
          this.isLoading = false;
          this.products = response.items;
          this.memoryCache.set(cacheKey, this.products);
          this.cdr.markForCheck();
        },
        error => {
          this.error = error.statusText;
          this.isLoading = false;
          this.cdr.markForCheck();
        }
      );
    }

    this.cdr.markForCheck();
  }

  getFirstImage = (images: any[]): string => (images.length && images[0].medium ? `url(${images[0].medium})` : null);

  getCategoriesArray(categories: {}): Array<string> {
    return Object.values(categories);
  }

  trackById = (index, product: Product) => product.id;
}
