<nav class="cart" [ngClass]="{'cart--open': isOpen, 'cart--no-animation': noAnimation}">
  <div class="cart__overlay" (click)="close()"></div>
  <div class="cart__wrapper">
    <div class="cart__header">
        <h4 class="cart__header__title">Varukorg</h4>
        <button type="button" class="cart__header__close hamburger hamburger--slider is-active" (click)="close()">
          <span class="hamburger-box"> <span class="hamburger-inner"></span> </span>
        </button>
    </div>  
    <div #scrollcontent class="cart__content">
      <p class="cart__empty" *ngIf="!products.length">Din varukorg är tom</p>
      <app-cart-list class="cart__list"></app-cart-list>
    </div>
    <div class="cart__footer">
      <span class="cart__footer__price" *ngIf="products.length">
        <span class="cart__footer__price__label">
          Totalt<br> 
          <span class="cart__footer__price__vat">inkl moms:</span>
        </span> 
        <strong class="cart__footer__price__total">{{getTotal() | price}}&nbsp;kr</strong>
      </span>
      <button class="btn btn-primary" [disabled]="!products.length" (click)="checkout()">Betala</button>
    </div>
  </div>
</nav>