import { Injectable } from '@angular/core';
import { MemoryCacheService } from './cache/memory-cache.service';
import { SessionCacheService } from './cache/session-cache.service';
import { PermanentCacheService } from './cache/permanent-cache.service';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class StoreService {
  
  private cartState: string = 'closed';
  private cartStateSubject = new Subject<string>();

  constructor(
    private memoryCache: MemoryCacheService,
    private sessionCache: SessionCacheService,
    private permanentCache: PermanentCacheService
  ) { }
  

  getCartState = (): string => this.cartState;
  observeCartState = (): Observable<string> => this.cartStateSubject.asObservable();
  setCartState = (state: string) => this.cartStateSubject.next(this.cartState = state.toLowerCase());
}
