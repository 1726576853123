export const environment = {
  production: true,
  hmr: false,
  api: 'api',
  contentful: {
    space: 'bq4vfjhqoxfu',
    accessToken: 'e9mSNP2W31JrXUZTBNv4pmbMx1dszrW2Sn0QLZn0dJ0',
    previewAccessToken: 'NKKIZls5dl2smiEIPgzw-Nm7tSMZKdZNbA9I1Ln4IIM'
  }
};
