import { Component } from '@angular/core';
import { SessionCacheService } from './services/cache/session-cache.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  get isTest(): boolean {
    return this.sessionCache.get('test');
  }

  constructor(private sessionCache: SessionCacheService) {}
}
