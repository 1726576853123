import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { FilterService } from "src/app/services/filter.service";
import FilterCategory from "src/app/models/filter-category.model";
import { Subject, Subscription } from "rxjs";
import { finalize, take, takeUntil } from "rxjs/operators";
import { ContentfulService } from "src/app/services/contentful.service";
import { Asset } from "contentful";

@Component({
  selector: "app-categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["./categories.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoriesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() assets: Array<Asset>;

  isError: any;
  isLoading: boolean;
  categories: Array<FilterCategory> = [];

  private ngUnsubscribe$ = new Subject<void>();

  constructor(
    private filterService: FilterService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.assets.currentValue !== changes.assets.previousValue) {
      this.mapAssetsToCategories();
    }
  }

  ngOnInit() {
    this.isLoading = true;

    this.filterService.categoryChanges.pipe(take(1), finalize(() => {
      this.isLoading = false;
      this.cd.markForCheck();
    })).subscribe(categories => {
      this.categories = categories;
      this.isError = this.categories.length === 0;
      this.mapAssetsToCategories();
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  private mapAssetsToCategories() {
    this.categories.forEach((category, index) => {
      const categoryName = category.label.replace(/[äå]/i, 'a').replace(/[ö]/i, 'o').replace(/[^a-z]/i, '-').toLowerCase();
      const asset = this.assets.find(({ fields }) => {
        const fileName = fields.file.fileName.replace(/[äå]/i, 'a').replace(/[ö]/i, 'o').replace(/[^a-z]/i, '-').toLowerCase();
        const title = fields.title.replace(/[äå]/i, 'a').replace(/[ö]/i, 'o').replace(/[^a-z]/i, '-').toLowerCase();
        if (fileName.includes(categoryName) || title.includes(categoryName)) {
          return true;
        }
      });
      if (asset) {
        this.categories[index].image = asset.fields.file.url;
      }
    });
  }
}
