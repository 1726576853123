import OrderItemMeta from './order-item-meta.model';
import Product from './product.model';

export default class OrderItem {
  public id?: number;

  public order_id?: string;
  public infrasec_id: number;
  public name: string;
  public type: string;

  public quantity: number;
  public price: number;
  public vat: number;

  public created_at?: string;
  public updated_at?: string;

  public meta?: OrderItemMeta[];
  public images?: any[];
  public addons?: OrderItem[];

  public static createFromProducts(products: Product[]): OrderItem[] {
    return products.map(product => ({
      infrasec_id: product.id,
      name: product.name,
      type: product.type,
      quantity: product.quantity,
      price: product.total - product.vat,
      vat: product.vat,
      addons: product.addons ? this.createFromProducts(product.addons) : [],
      meta: OrderItemMeta.createFromAttributes(product.attributes)
    }));
  }
}
