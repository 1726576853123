import Store from './store.model';
import Shipping from './shipping.model';
import Product from './product.model';

export default class Cart {
  public store?: Store;
  public products: Product[] = [];
  public shipping?: Shipping;

  public delivery_at?: string;
  public delivery_time?: string;
  public delivery_zip?: number;
  public delivery_city?: string;

  public total = (includeShipping: boolean = true) => {
    let cost = 0;
    if (this.products.length) {
      // Products
      this.products.forEach(product => {
        cost += product.total * product.quantity;
        // Addons
        if (product.addons.length) {
          cost += product.addons
            .map(addon => addon.total * addon.quantity)
            .reduce((sum, x) => sum + x);
        }
      });

      // Shipping
      if (this.shipping && includeShipping) {
        cost += this.shipping.charge.total;
      }
    }
    return cost;
  };

  public vat = () => {
    let cost = 0;
    if (this.products.length) {
      // Products
      cost = this.products
        .map(product => product.vat * product.quantity)
        .reduce((sum, x) => sum + x);
      // Shipping
      const shipping = this.shipping;
      if (shipping) {
        cost += shipping.charge.vat;
      }
    }
    return cost;
  };
}
