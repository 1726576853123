import { Injectable } from '@angular/core';
import Cache from './cache';

@Injectable({
  providedIn: 'root'
})
export class SessionCacheService extends Cache {
  constructor() { 
    super('session');
  }
}
