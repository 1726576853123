import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { StoreService } from 'src/app/services/store.service';
import { NavService } from 'src/app/services/nav.service';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import Order from 'src/app/models/order.model';
import OrderItem from 'src/app/models/order-item.model';
import { NgbDateStruct, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { finalize } from 'rxjs/operators';
import Store from 'src/app/models/store.model';
import { HittaComponent } from 'src/app/components/hitta.se/hitta.se.component';
import Product from 'src/app/models/product.model';
import { Subscription } from 'rxjs';
import { SessionCacheService } from 'src/app/services/cache/session-cache.service';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutPageComponent implements OnInit, OnDestroy {
  @ViewChild('scrollcontent', { static: true }) scrollcontent: ElementRef;

  cacheKeyOrder = 'order';
  products: Product[];

  form: FormGroup;

  showStores: boolean;
  submitted: boolean;
  isLoading: boolean;
  error: any;
  step = 0;
  maxStep = 2;

  scrollbars: any;

  isCalendarOpen: boolean;
  isFuneralTimeOpen: boolean;

  isZipError: boolean;
  isDateError: boolean;
  isDeliveryError: boolean;
  isServerError: boolean;
  isZipSearching: boolean;

  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  selectedDate: NgbDateStruct;

  selectedZip: {
    zip: number;
    city: string;
  };

  stores: Store[] = [];

  subscriptions: Subscription[] = [];

  get isFuneral(): boolean {
    return this.form.value.step0.type === 'funeral';
  }

  get isSwish(): boolean {
    return this.step2.get('payment').value === 'swish';
  }

  get funeralTimes(): string[] {
    return [
      '08:00',
      '08:15',
      '08:30',
      '08:45',
      '09:00',
      '09:15',
      '09:30',
      '09:45',
      '10:00',
      '10:15',
      '10:30',
      '10:45',
      '11:00',
      '11:15',
      '11:30',
      '11:45',
      '12:00',
      '12:15',
      '12:30',
      '12:45',
      '13:00',
      '13:15',
      '13:30',
      '13:45',
      '14:00',
      '14:15',
      '14:30',
      '14:45',
      '15:00',
      '15:15',
      '15:30',
      '15:45',
      '16:00',
      '16:15',
      '16:30',
      '16:45',
      '17:00'
    ];
  }

  get isFuneralTimeError(): boolean {
    return !this.step0.get('delivery_time').valid;
  }

  get selectedFuneralTime(): string {
    const value = this.step0.get('delivery_time').value;
    return value ? value : '00:00';
  }

  get isTest(): boolean {
    return this.sessionCache.get('test');
  }

  get step0(): AbstractControl {
    return this.form.get('step0');
  }

  get step1(): AbstractControl {
    return this.form.get('step1');
  }

  get step2(): AbstractControl {
    return this.form.get('step2');
  }

  get alphaNumericValidation(): ValidatorFn {
    return Validators.pattern(/^[a-z0-9_\-äöåèé&\s]+$/i);
  }

  get phoneValidation(): ValidatorFn {
    return Validators.pattern(/^[0-9\(\)+\-\s]+$/i);
  }

  constructor(
    private cartService: CartService,
    private storeService: StoreService,
    private sessionCache: SessionCacheService,
    private navService: NavService,
    private fb: FormBuilder,
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private seoService: SeoService
  ) {
    this.products = this.cartService.items();

    this.subscriptions.push(
      this.cartService.observe().subscribe(cart => {
        this.products = cart.products;
        this.cdr.markForCheck();
      })
    );
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.storeService.setCartState('closed');

    this.scrollbars = OverlayScrollbars(this.scrollcontent.nativeElement, {
      className: 'os-theme-dark',
      sizeAutoCapable: true,
      paddingAbsolute: true,
      scrollbars: {
        autoHide: 'leave',
        autoHideDelay: 100
      }
    });

    this.initForms();
    this.initCalendar();

    this.seoService.setMeta('Kassan');
  }

  ngOnDestroy() {
    if (this.scrollbars) {
      this.scrollbars.destroy();
    }
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  initCalendar() {
    const state: Order = this.loadFormState() || new Order();

    let date = null;
    if (state.delivery_at) {
      date = new Date(state.delivery_at);
    } else if (this.cartService.date()) {
      date = new Date(this.cartService.date());
    }

    if (date) {
      this.selectedDate = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      };
    }

    const minimum = new Date();
    this.minDate = {
      year: minimum.getFullYear(),
      month: minimum.getMonth() + 1,
      day: minimum.getDate()
    };

    const maximum = new Date();
    maximum.setMonth(maximum.getMonth() + 3);
    this.maxDate = {
      year: maximum.getFullYear(),
      month: maximum.getMonth() + 1,
      day: maximum.getDate()
    };
  }

  initForms() {
    const state: Order = this.loadFormState() || new Order();

    this.form = this.fb.group({
      step0: this.fb.group({
        type: [state.type || 'general', [Validators.required]],
        delivery_at: [state.delivery_at, [Validators.required]],

        delivery_time: [state.delivery_time],
        receiver_church: [state.receiver_church],

        meta: this.fb.group({
          message: [state.meta && state.meta.message ? state.meta.message.value : null, [Validators.required]]
        }),

        receiver_firstname: [state.receiver_firstname, [Validators.required, this.alphaNumericValidation]],
        receiver_lastname: [state.receiver_lastname, [Validators.required, this.alphaNumericValidation]],
        receiver_phone: [state.receiver_phone],
        receiver_address: [state.receiver_address, [Validators.required]],
        receiver_zip: [state.receiver_zip, [Validators.required, Validators.pattern(/[0-9]{5}|[0-9]{3}\s[0-9]{2}/)]],
        receiver_city: [state.receiver_city, [Validators.required]]
      }),

      step1: this.fb.group({
        sender_name: [state.sender_name, [Validators.required, this.alphaNumericValidation]],
        sender_phone: [state.sender_phone, [Validators.required, Validators.minLength(6), this.phoneValidation]],
        sender_email: [state.sender_email, [Validators.required, Validators.email]],

        meta: this.fb.group({
          other: [state.meta && state.meta.other ? state.meta.other.value : null]
        })
      }),

      step2: this.fb.group({
        payment: [state.payment, [Validators.required]],
        store: [null, [Validators.required]],
        shipping: [null, [Validators.required]]
      })
    });

    // Load dummy data for test
    if (window.location.href.indexOf('test') >= 0) {
      this.loadDummyData();
    }

    this.onTypeChange();
  }

  loadDummyData() {
    this.form.patchValue({
      step0: {
        delivery_at: '',

        delivery_time: '08:00',
        receiver_church: 'TEST',

        meta: {
          message: 'TEST'
        },

        receiver_firstname: 'Kalle',
        receiver_lastname: 'Anka',
        receiver_phone: '0702180856',
        receiver_address: 'TEST 0',
        receiver_zip: '235 94',
        receiver_city: 'TEST'
      },

      step1: {
        sender_name: 'Arnolds Verins',
        sender_phone: '+46(0)70 - 218 08 56',
        sender_email: 'arnolds.verins@gmail.com',

        meta: {
          other: 'TEST'
        }
      }
    });

    const date = new Date();
    this.onDateSelect({
      year: date.getFullYear(),
      day: date.getDate() + 1,
      month: date.getMonth() + 1
    });
  }

  loadStores() {
    if (!this.isDateError && !this.isZipError) {
      this.isLoading = true;
      const zip = this.form.value.step0.receiver_zip;
      const date = this.getDate();
      this.apiService.getStore(zip, date).subscribe(
        stores => {
          this.isLoading = false;
          this.stores = stores;
          if (!this.stores.length) {
            this.isDeliveryError = true;
            this.apiService
              .log(
                `Could not load stores for zip "${this.step0.get('receiver_zip').value}", city "${this.step0.get('receiver_city').value
                }" and date ${this.getDate()}`,
                window.location.href,
                'notice'
              )
              .subscribe();
          } else {
            window.scrollTo(0, 0);
            this.showStores = true;
            this.submitted = false;
            this.step = 1;

            // If only one store, auto select.
            this.step2.get('store').setValue(this.stores.length === 1 ? this.stores[0] : null);

            this.onStoreChange();
          }
          this.cdr.markForCheck();
        },
        () => {
          this.isLoading = false;
          this.isServerError = true;
          this.cdr.markForCheck();
        }
      );
    }
  }

  nextStep() {
    const zip = this.form.value.step0.receiver_zip;

    this.error = false;
    this.isServerError = false;
    this.isDeliveryError = false;
    this.isDateError = !this.selectedDate;
    this.isZipError = zip ? zip.toString().replace(/[^0-9]/g, '').length !== 5 : true;

    if (this.step === 0 && this.step0.valid) {
      this.submitted = false;
      if (!this.isZipError) {
        this.loadStores();
        this.saveFormState();
      }
    } else if (this.step === 1 && this.step1.valid) {
      window.scrollTo(0, 0);
      this.submitted = false;
      this.step += 1;
      this.saveFormState();
    } else if (this.step === 2) {
      this.submitForm();
      this.saveFormState();
    } else {
      this.submitted = true;
    }

    this.cdr.markForCheck();
  }

  submitForm() {
    this.submitted = true;
    this.isServerError = false;

    if (this.form.valid) {
      const order = this.generateOrderData();

      this.error = false;
      this.isLoading = true;
      this.apiService
        .createOrder(order)
        .pipe(
          finalize(() => {
            this.cdr.markForCheck();
          })
        )
        .subscribe(
          response => {
            this.clearFormState();

            if (response.url.includes(document.domain)) {
              this.navService.nagivate(response.url, { replaceUrl: false });
            } else {
              window.location.href = response.url;
            }
          },
          response => {
            this.isLoading = false;
            this.error = response.status === 422 ? 'validation' : true;
          }
        );
    } else {
      this.focusFormElement(Object.keys(this.form.controls).find(key => !this.form.get(key).valid));
    }
  }

  previousStep() {
    this.error = false;

    if (this.step > 0) {
      this.step -= 1;
      window.scrollTo(0, 0);
    } else if (this.step < this.maxStep) {
      this.step += 1;
      window.scrollTo(0, 0);
    }
    this.cdr.markForCheck();
  }

  findReceiverAddress(by: string) {
    const modal: NgbModalRef = this.modalService.open(HittaComponent);

    modal.result.then(
      address => {
        this.step0.get('receiver_firstname').setValue(address.firstname);
        this.step0.get('receiver_lastname').setValue(address.lastname);
        this.step0.get('receiver_address').setValue(address.address);
        this.step0.get('receiver_zip').setValue(address.zip);
        this.step0.get('receiver_city').setValue(address.city);
        if (address.phone) {
          this.step0.get('receiver_phone').setValue(address.phone);
        }

        this.cdr.markForCheck();
      },
      () => { }
    );

    const step0 = this.form.value.step0;

    const query =
      by === 'name'
        ? `${step0.receiver_firstname} ${step0.receiver_lastname} ${step0.receiver_city || ''}`
        : this.form.value.step0.receiver_phone || '';
    modal.componentInstance.loadData(query);
  }

  getDate = () => {
    return this.selectedDate
      ? `
          ${this.selectedDate.year}-
          ${this.selectedDate.month < 10 ? `0${this.selectedDate.month}` : this.selectedDate.month}-
          ${this.selectedDate.day < 10 ? `0${this.selectedDate.day}` : this.selectedDate.day}
        `.replace(/[\n\s\t]/g, '')
      : null;
  };

  focusFormElement(path: string) {
    const nativeElement = <HTMLElement>document.querySelector(`[formcontrolname="${path}"]`);
    if (nativeElement) {
      nativeElement.focus();
    }
  }

  toggleCalendar(forceOpen: boolean = false) {
    if (!this.isCalendarOpen) {
      this.isCalendarOpen = true;
      this.cdr.markForCheck();
      setTimeout(() => {
        document.addEventListener('click', this.closeCalendar);
      }, 50);
    } else if (!forceOpen) {
      this.closeCalendar();
    }
  }

  closeCalendar = () => {
    this.isCalendarOpen = false;
    document.removeEventListener('click', this.closeCalendar);
    this.cdr.markForCheck();
  }

  toggleFuneralTime(forceOpen: boolean = false) {
    if (!this.isFuneralTimeOpen) {
      this.isFuneralTimeOpen = true;
      this.cdr.markForCheck();
      setTimeout(() => {
        document.addEventListener('click', this.closeFuneralTime);
      }, 50);
    } else if (!forceOpen) {
      this.closeFuneralTime();
    }
  }

  closeFuneralTime = () => {
    this.isFuneralTimeOpen = false;
    document.removeEventListener('click', this.closeFuneralTime);
    this.cdr.markForCheck();
  };

  setFuneralTime(time: string) {
    this.step0.get('delivery_time').setValue(time);
    this.closeFuneralTime();
  }

  stopPropagation = (event: Event) => event.stopPropagation();

  onDateSelect(date: NgbDateStruct) {
    this.selectedDate = date;
    this.isDateError = false;
    this.isDeliveryError = false;
    this.step0.get('delivery_at').setValue(this.getDate());

    setTimeout(() => this.closeCalendar(), 50);
  }

  onStoreChange() {
    this.submitted = false;
    this.cartService.setStore(this.form.value.step2.store);

    this.step2.get('shipping').setValue(null);
    this.onShippingChange();
  }

  onShippingChange() {
    this.cartService.setShipping(this.form.value.step2.shipping);
  }

  onTypeChange() {
    this.step0.get('delivery_time').setValidators(this.isFuneral ? Validators.required : null);
    this.step0.get('receiver_church').setValidators(this.isFuneral ? Validators.required : null);
    this.step0
      .get('receiver_phone')
      .setValidators(this.isFuneral ? null : [Validators.required, Validators.minLength(6), this.phoneValidation]);

    // Update state
    this.step0.get('delivery_time').updateValueAndValidity();
    this.step0.get('receiver_church').updateValueAndValidity();
    this.step0.get('receiver_phone').updateValueAndValidity();
  }

  close = () => this.navService.back('/');

  isPhoneValid = () => this.step0.get('receiver_phone').valid;
  isNameValid = () => this.step0.get('receiver_firstname').valid && this.step0.get('receiver_lastname').valid;

  getTotal = (): number => this.cartService.total();
  getTotalVat = () => this.cartService.vat();
  getShipping = () => (this.cartService.shipping() ? this.cartService.shipping().charge.total : 0);
  getShippingTitle = () => (this.cartService.shipping() ? this.cartService.shipping().name : 'Frakt');
  getReceiverDate = () => this.cartService.date();
  getReceiverZip = () => this.cartService.zip();

  private generateOrderData(): Order {
    const order = Order.crateFromFormValues({
      ...this.form.value.step0,
      ...this.form.value.step1,
      ...this.form.value.step2,
      meta: {
        message: this.form.value.step0.meta.message,
        other: this.form.value.step1.meta.other
      }
    });

    order.amount = this.getTotal();
    order.items = OrderItem.createFromProducts(this.cartService.items());
    order.store = this.cartService.store();
    order.shipping = this.cartService.shipping();

    // Remove values for different order types
    if (order.type === 'general') {
      delete order.delivery_time;
      delete order.receiver_church;
    } else if (order.type === 'funeral') {
      delete order.receiver_phone;
    }

    return order;
  }

  private saveFormState() {
    const order = this.generateOrderData();
    this.sessionCache.set(this.cacheKeyOrder, order);
  }

  private clearFormState() {
    this.sessionCache.delete(this.cacheKeyOrder);
  }

  private loadFormState = (): Order => this.sessionCache.get(this.cacheKeyOrder);
}
